import React, {
	ReactNode, createContext, useMemo, useState,
} from 'react'

// ----------------------------------------------------------------------

type PresentationContextProps = {
 isNative: boolean
 setIsNative: React.Dispatch<React.SetStateAction<boolean>>
}

const initialState: PresentationContextProps = {
	isNative: false,
	setIsNative: () => {},
}

const PresentationContext = createContext(initialState)

// ----------------------------------------------------------------------

type PresentationProviderProps = {
  children: ReactNode
};

function PresentationProvider({ children }: PresentationProviderProps) {
	const [isNative, setIsNative] = useState<boolean>(false)

	const value = useMemo(() => {
		const a: PresentationContextProps = {
			isNative,
			setIsNative,
		}
		return a
	}, [isNative])

	return (
		<PresentationContext.Provider
			value={value}
		>
			{children}
		</PresentationContext.Provider>
	)
}

export { PresentationProvider, PresentationContext }
