import { createSlice } from '@reduxjs/toolkit'
import {
	doc, getDoc, getFirestore, onSnapshot, query,
	increment, serverTimestamp, setDoc, Timestamp, collection, getDocs, where, addDoc, Unsubscribe, deleteDoc, FieldValue, orderBy, collectionGroup, deleteField, limitToLast, arrayRemove, getCountFromServer,
} from 'firebase/firestore'
import { logger } from 'src/services/logger'

import { TDecisionState, TDecisionStatus } from 'src/types/DecisionHub'
import * as FirestoreService from 'src/services/FirestoreService'
import { dispatch } from '../store'
// ----------------------------------------------------------------------

type TSetPendingDecisionsPayload = {
	count: number
}

const initialState: TDecisionState = {
	pendingDecisions: null,
}

const slice = createSlice({
	name: 'decision-hub',
	initialState,
	reducers: {

		setPendingDecisions(state, action) {
			const { count }: TSetPendingDecisionsPayload = action.payload
			state.pendingDecisions = count
		},

	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface ListenForPendingDecisionsProps {
	uid: string
}

export const listenForPendingDecisions = ({ uid }:ListenForPendingDecisionsProps) => (): Unsubscribe => {
	logger.log('listenForPendingDecisions')

	const unsubscribe = FirestoreService.streamDecisionHub({
		uid,
		snapshot: (querySnapshot) => {
			dispatch(slice.actions.setPendingDecisions({
				count: querySnapshot.size,
			}))
		},
		error: (error) => logger.error(error),
	})

	return unsubscribe
}
