import { createSlice } from '@reduxjs/toolkit'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { ICustomTokenPfpResponse } from 'src/@types/customizedToken'
import { store } from '../store'
import { SuggestedPfpState } from '../../types/SuggestedPfp'

// ----------------------------------------------------------------------

const initialState: SuggestedPfpState = {
	isLoading: false,
	error: false,
	suggestedPfps: [],
}

const slice = createSlice({
	name: 'suggestedPfp',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		// GET PROMOTIONS
		getSuggestedPfpSuccess(state, action) {
			state.isLoading = false
			state.suggestedPfps = action.payload
		},
	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getAllSuggestedPfps() {
	return async () => {
		const { dispatch } = store
		dispatch(slice.actions.startLoading())
		try {
			// console.log('pfps-custom-fns-getSuggestedPfps')
			const functions = getFunctions()
			const fn = httpsCallable(functions, 'pfps-custom-fns-getSuggestedPfps')
			const requestData = {} as any

			const spaces:Array<ICustomTokenPfpResponse> = (await fn(requestData))?.data as Array<ICustomTokenPfpResponse>

			dispatch(slice.actions.getSuggestedPfpSuccess(spaces))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
