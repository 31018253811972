import React, {
	ReactNode, useEffect, createContext, useMemo, useCallback,
} from 'react'
// hooks
import useLocalStorage from '../hooks/useLocalStorage'
// utils
import getColorPresets, { colorPresets, /* defaultPreset */ airbnbPreset } from '../utils/getColorPresets'
// config
import { defaultSettings } from '../config'
// @type
import {
	ThemeMode,
	ThemeLayout,
	ThemeContrast,
	ThemeDirection,
	ThemeColorPresets,
	SettingsContextProps,
} from '../components/settings/type'

// ----------------------------------------------------------------------

const initialState: SettingsContextProps = {
	...defaultSettings,
	// Mode
	onToggleMode: () => {},
	onChangeMode: () => {},

	// Direction
	onToggleDirection: () => {},
	onChangeDirection: () => {},
	onChangeDirectionByLang: () => {},

	// Layout
	onToggleLayout: () => {},
	onChangeLayout: () => {},

	// Contrast
	onToggleContrast: () => {},
	onChangeContrast: () => {},

	// Color
	onChangeColor: () => {},
	setColor: airbnbPreset,
	colorOption: [],

	// Stretch
	onToggleStretch: () => {},

	// Reset
	onResetSetting: () => {},
}

const SettingsContext = createContext(initialState)

// ----------------------------------------------------------------------

type SettingsProviderProps = {
  children: ReactNode
};

function SettingsProvider({ children }: SettingsProviderProps) {
	const [settings, setSettings] = useLocalStorage('settings', {
		themeMode: initialState.themeMode,
		themeLayout: initialState.themeLayout,
		themeStretch: initialState.themeStretch,
		themeContrast: initialState.themeContrast,
		themeDirection: initialState.themeDirection,
		themeColorPresets: initialState.themeColorPresets,
	})

	const isArabic = typeof localStorage !== 'undefined' ? localStorage.getItem('i18nextLng') === 'ar' : false

	useEffect(() => {
		if (isArabic) {
			onChangeDirectionByLang('ar')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isArabic])

	// Mode

	const onToggleMode = useCallback(() => {
		setSettings({
			...settings,
			themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
		})
	}, [setSettings, settings])

	const onChangeMode = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeMode: (event.target as HTMLInputElement).value as ThemeMode,
		})
	}, [setSettings, settings])

	// Direction

	const onToggleDirection = useCallback(() => {
		setSettings({
			...settings,
			themeDirection: settings.themeDirection === 'rtl' ? 'ltr' : 'rtl',
		})
	}, [setSettings, settings])

	const onChangeDirection = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeDirection: (event.target as HTMLInputElement).value as ThemeDirection,
		})
	}, [setSettings, settings])

	const onChangeDirectionByLang = useCallback((lang: string) => {
		setSettings({
			...settings,
			themeDirection: lang === 'ar' ? 'rtl' : 'ltr',
		})
	}, [setSettings, settings])

	// Layout

	const onToggleLayout = useCallback(() => {
		setSettings({
			...settings,
			themeLayout: settings.themeLayout === 'vertical' ? 'horizontal' : 'vertical',
		})
	}, [setSettings, settings])

	const onChangeLayout = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeLayout: (event.target as HTMLInputElement).value as ThemeLayout,
		})
	}, [setSettings, settings])

	// Contrast

	const onToggleContrast = useCallback(() => {
		setSettings({
			...settings,
			themeContrast: settings.themeContrast === 'default' ? 'bold' : 'default',
		})
	}, [setSettings, settings])

	const onChangeContrast = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeContrast: (event.target as HTMLInputElement).value as ThemeContrast,
		})
	}, [setSettings, settings])

	// Color

	const onChangeColor = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeColorPresets: (event.target as HTMLInputElement).value as ThemeColorPresets,
		})
	}, [setSettings, settings])

	// Stretch

	const onToggleStretch = useCallback(() => {
		setSettings({
			...settings,
			themeStretch: !settings.themeStretch,
		})
	}, [setSettings, settings])

	// Reset

	const onResetSetting = useCallback(() => {
		setSettings({
			themeMode: initialState.themeMode,
			themeLayout: initialState.themeLayout,
			themeStretch: initialState.themeStretch,
			themeContrast: initialState.themeContrast,
			themeDirection: initialState.themeDirection,
			themeColorPresets: initialState.themeColorPresets,
		})
	}, [setSettings])
	const value = useMemo(() => ({
		...settings,

		// Mode
		onToggleMode,
		onChangeMode,

		// Direction
		onToggleDirection,
		onChangeDirection,
		onChangeDirectionByLang,

		// Layout
		onToggleLayout,
		onChangeLayout,

		// Contrast
		onChangeContrast,
		onToggleContrast,

		// Stretch
		onToggleStretch,

		// Color
		onChangeColor,
		setColor: getColorPresets(settings.themeColorPresets),
		colorOption: colorPresets.map((color) => ({
			name: color.name,
			value: color.main,
		})),

		// Reset
		onResetSetting,
	} as SettingsContextProps), [
		onChangeColor,
		onToggleStretch,
		onChangeLayout,
		onToggleDirection,
		onChangeMode,
		onToggleMode,
		onChangeDirection,
		onChangeContrast,
		onResetSetting,
		onToggleContrast,
		onToggleLayout,
		settings,
		onChangeDirectionByLang,
	])
	return (
		<SettingsContext.Provider
			value={value}
		>
			{children}
		</SettingsContext.Provider>
	)
}

export { SettingsProvider, SettingsContext }
