import { createSlice, Dispatch } from '@reduxjs/toolkit'
import {
	doc, getDoc, getFirestore, onSnapshot, query,
	increment, serverTimestamp, setDoc, Timestamp, collection, getDocs, where, addDoc, Unsubscribe, orderBy, limit, collectionGroup, startAfter, QueryDocumentSnapshot, DocumentData, getCountFromServer, FieldValue, deleteField, deleteDoc,
} from 'firebase/firestore'
import { ConsoleLogger, logger } from 'src/services/logger'

import { FeedState, IFeedItemFromDb, TFeedItem } from 'src/types/Feed'
import NavItem from 'src/components/nav-section/vertical/NavItem'
import { Field } from 'formik'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { PostState } from 'src/types/Posts'
import { firestoreToFeedItem } from 'src/utils/feed'
import { store } from '../store'
// ----------------------------------------------------------------------

export type TSort = 'byCreatedDesc'

type TGetPageSuccessPayload = {
	items: Array<TFeedItem>
	lastDoc: QueryDocumentSnapshot<DocumentData> | null
	pageSize: number
	reset: boolean
	authorUid: string
}

type TGetTotalMoveSuccessPayload = {
	total: number
}

type TToggleLikePayload = {
	authorUid: string
	item: TFeedItem
	isLiked: boolean
}

type TSetRepromptedPayload = {
	item: TFeedItem
}

type TDeleteItemPayload = {
	item: TFeedItem
	authorUid: string
}

type TStartLoading = {
	authorUid: string
}

type TFailure = {
	authorUid: string
}

const initialState: PostState = {
	byAuthor: {},
}

const slice = createSlice({
	name: 'posts',
	initialState,
	reducers: {

		startLoadingPage(state, action) {
			const { authorUid }:TStartLoading = action.payload
			state.byAuthor[authorUid] = {
				isLoading: true,
				isLoaded: false,
				error: false,
				items: [],
				lastDoc: null,
				hasMore: false,
			}
		},

		getPageFailure(state, action) {
			const { authorUid }:TFailure = action.payload
			state.byAuthor[authorUid] = {
				isLoading: false,
				isLoaded: true,
				error: true,
				items: [],
				lastDoc: null,
				hasMore: false,
			}
		},

		getPageSuccess(state, action) {
			const {
				lastDoc, items, pageSize, reset, authorUid,
			}: TGetPageSuccessPayload = action.payload

			state.byAuthor[authorUid] = {
				isLoading: false,
				isLoaded: true,
				error: false,
				items: [
					...(!reset ? state.byAuthor[authorUid].items : []),
					...items,
				],
				lastDoc,
				hasMore: items.length >= pageSize,
			}
		},

		toggleLike(state, action) {
			const { item: targetedItem, isLiked, authorUid }:TToggleLikePayload = action.payload
			const idx = state.byAuthor[authorUid].items.findIndex((item) => item.id === targetedItem.id)
			if (idx >= 0) {
				const { likeCount = 0 } = state.byAuthor[authorUid].items[idx]
				state.byAuthor[authorUid].items[idx].isLiked = isLiked
				state.byAuthor[authorUid].items[idx].likeCount = (likeCount + (isLiked ? 1 : -1))
			}
		},

		// setReprompted(state, action) {
		// 	const { item: targetedItem }:TSetRepromptedPayload = action.payload
		// 	const idx = state.items.findIndex((item) => item.id === targetedItem.id)
		// 	if (idx >= 0) {
		// 		const { reprompts = 0 } = state.items[idx]
		// 		state.items[idx].isReprompted = true
		// 		state.items[idx].reprompts = reprompts + 1
		// 	}
		// },

		deleteItem(state, action) {
			const { item: targetedItem, authorUid }:TDeleteItemPayload = action.payload
			const idx = state.byAuthor[authorUid].items.findIndex((item) => item.id === targetedItem.id)
			if (idx >= 0) {
				if (idx >= 0) {
					state.byAuthor[authorUid].items.splice(idx, 1)
				}
			}
		},

	},
})

// Reducer
export default slice.reducer

// --------------------------------------------------

interface GetPostsByAuthorPageProps {
	pageSize: number
	uid: string
	authorUid: string
	reset?: boolean
}

export function getPostsByAuthor(props:GetPostsByAuthorPageProps) {
	return async () => {
		logger.log('getPostsByAuthor, getPage', props)
		const { dispatch, getState } = store
		const {
				 pageSize, uid, reset, authorUid,
		} = props

		try {
			dispatch(slice.actions.startLoadingPage({ authorUid }))
			const { feed2: feedState }:{feed2: FeedState} = getState()
			const startAfterDoc: QueryDocumentSnapshot<DocumentData, DocumentData> | null = feedState.lastDoc as QueryDocumentSnapshot<DocumentData, DocumentData>
			const db = getFirestore()
			const collectionRef = collection(db, 'users', uid, 'feed_items')

			let q

			if (reset || !startAfterDoc) {
				q = query(
					collectionRef,
					where('authorUid', '==', authorUid),
					orderBy('createdAt', 'desc'),
					limit(pageSize),
				)
			} else {
				q	 = query(
					collectionRef,
					where('authorUid', '==', authorUid),
					orderBy('createdAt', 'desc'),
					limit(pageSize),
					startAfter(startAfterDoc),
				)
			}

			const querySnapshot = await getDocs(q)

			if (!querySnapshot.empty) {
				logger.log('new post data')
				const items: Array<TFeedItem> = querySnapshot.docs.map((item) => firestoreToFeedItem(item))

				dispatch(slice.actions.getPageSuccess({
					items,
					reset,
					lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
					pageSize,
					authorUid,
				}))
			} else {
				dispatch(slice.actions.getPageSuccess({
					items: [],
					reset,
					lastDoc: null,
					pageSize,
					authorUid,
				}))
			}
		} catch (err) {
			logger.error(err)
			dispatch(slice.actions.getPageFailure({ authorUid }))
		}
	}
}

// ----------------------------------------------------------------------
interface ToggleLikeProps {
	authorUid: string
	item: TFeedItem
}
export function toggleLike(props: ToggleLikeProps) {
	return async (dispatch: Dispatch) => {
		const { authorUid, item } = props
		logger.log('toggleLike', props)
		const newIsLiked = !(item?.isLiked ?? false)

		dispatch(slice.actions.toggleLike({ authorUid, item, isLiked: newIsLiked }))
	}
}

// ----------------------------------------------------------------------

// interface SetRepromptedProps {
// 	uid: string
// 	item: TFeedItem
// }
// export function setReprompted(props: SetRepromptedProps) {
// 	return async (dispatch: Dispatch) => {
// 		const { uid, item } = props
// 		logger.log('setReprompted', props)
// 		// const newIsLiked = !(item?.isLiked ?? false)

// 		// dispatch(slice.actions.setReprompted({ item }))
// 	}
// }

// // ----------------------------------------------------------------------

// interface ReportProps {
// 	uid: string
// 	item: TFeedItem
// }
// export function report(props: ReportProps) {
// 	return async (dispatch: Dispatch) => {
// 		const { uid, item } = props
// 		logger.log('report', props)
// 		// const newIsLiked = !(item?.isLiked ?? false)

// 		// dispatch(slice.actions.deleteItem({ item }))
// 	}
// }

// // ----------------------------------------------------------------------

interface DeleteFeedItemProps {
	authorUid: string
	item: TFeedItem
}
export function deleteFeedItem(props: DeleteFeedItemProps) {
	return async (dispatch: Dispatch) => {
		const { authorUid, item } = props
		logger.log('deleteFeedItem', props)

		dispatch(slice.actions.deleteItem({ authorUid, item }))
	}
}

// ----------------------------------------------------------------------
