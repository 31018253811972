
import { TPromptsChoicesSearchParams } from 'src/types/PromptChoices'
import { TPromptChoiceDirection } from 'src/types/Prompts'

export const initialSearchParamsState: TPromptsChoicesSearchParams = {
	// editMode: <TPromptChoiceEditMode>'tone',
	direction: <TPromptChoiceDirection>'continue',
	suggestion: null,
	suggestionCategoryId: null,
	// promptChoicesThemeId: defaultPromptChoiceTheme.themeId,
	promptChoicesThemeId: null,
}
