import { Dispatch, createSlice } from '@reduxjs/toolkit'
import { IPromotion } from 'src/types/IPromotion'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { ICustomTokenSpaceResponse, ISpacePublicProfile } from 'src/@types/space'
import {
	Timestamp, Unsubscribe, collection, getFirestore, limit, onSnapshot, query, where,
} from 'firebase/firestore'
import { logger } from 'src/services/logger'
import { store } from '../store'
// @types
import { SpaceState } from '../../types/Space'

// ----------------------------------------------------------------------

type TSuccessPayload = {
	spaces: Array<ISpacePublicProfile>
}

const initialState: SpaceState = {
	isLoading: false,
	error: false,
	spaces: [],
}

const slice = createSlice({
	name: 'space',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		// GET PROMOTIONS
		getSpacesSuccess(state, action) {
			state.isLoading = false
			state.spaces = action.payload
		},
	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getAllSpaces() {
	return async () => {
		const { dispatch } = store
		dispatch(slice.actions.startLoading())
		try {
			// console.log('pfps-custom-fns-getSuggestedSpaces')
			const functions = getFunctions()
			const fn = httpsCallable(functions, 'spaces-fns-getSuggestedSpaces')
			const requestData = {} as any

			const spaces:Array<ICustomTokenSpaceResponse> = (await fn(requestData))?.data as Array<ICustomTokenSpaceResponse>

			dispatch(slice.actions.getSpacesSuccess(spaces))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
