import { createSlice } from '@reduxjs/toolkit'
import { IPromotion } from 'src/types/IPromotion'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { store } from '../store'
// @types
import { PromotionState } from '../../types/Promotion'

// ----------------------------------------------------------------------

const initialState: PromotionState = {
	isLoading: false,
	error: false,
	promotions: [],
}

const slice = createSlice({
	name: 'promotions',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		// GET PROMOTIONS
		getPromotionsSuccess(state, action) {
			state.isLoading = false
			state.promotions = action.payload
		},
	},
})

// Reducer
export default slice.reducer

// ------------------------------------------------
/* const promotions:Array<IPromotion> = [

	{
		author: {
			customTokenId: 'abc',
			handle: 'juicyspa',
			name: 'Juicy Spa',
			tokenType: 'SPACE',
			tokenName: 'blah',
			imageUrl: 'https://firebasestorage.googleapis.com/v0/b/unreal-e7c6f.appspot.com/o/temp-stuff%2Fironopolis_pfp.jpg?alt=media&token=b6e44db9-ccd7-4949-8276-cc7cabb280c0',
			social: [],
			about: 'yo',
		},
		uObject: {
			name: 'Mega Beer',
			description: 'Smooth on the lips and guaranteed to improve your game',
		},
		media: 'https://firebasestorage.googleapis.com/v0/b/unreal-e7c6f.appspot.com/o/temp-stuff%2Fpromotions%2Fbeer.jpg?alt=media&token=61d7021b-4085-41b9-836c-14b9c383ad78',
	},
] */

// ----------------------------------------------------------------------

export function getAllPromotions() {
	return async () => {
		const { dispatch } = store
		dispatch(slice.actions.startLoading())
		try {
			const functions = getFunctions()
			const fn = httpsCallable(functions, 'spaces-fns-getSpacePromotions')
			const requestData = {} as any

			const promotions:Array<IPromotion> = (await fn(requestData))?.data as Array<IPromotion>

			dispatch(slice.actions.getPromotionsSuccess(promotions))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
