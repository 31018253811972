import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { useTheme } from '@mui/material/styles'
import { Box, BoxProps, SvgIcon } from '@mui/material'
// import { ReactComponent as LogoImage } from '../logo/logo_single.svg'
// import { ReactComponent as LogoImage } from '../logo/icon_darkbackground.svg'
// import { ReactComponent as LogoImage } from '../logo/newLogo/svg/icon_color.svg'
import { ReactComponent as LogoImage } from '../logo/newLogo/svg/unreal_swirls.svg'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean
}

export default function LogoJustIcon({ disabledLink = false, sx }: Props) {
	const theme = useTheme()

	// const PRIMARY_LIGHT = theme.palette.primary.light

	// const PRIMARY_MAIN = theme.palette.primary.main

	// const PRIMARY_DARK = theme.palette.primary.dark

	// OR
	const logo = 	(<SvgIcon component={LogoImage} sx={{ width: 40, height: 40, ...sx }} inheritViewBox />)

	// const logo = <Icon icon="/logo/logo_single.svg" width={20} height={20} />
	// <Image
	// 	alt="sketch icon"
	// 	src="/logo/logo_single.svg"
	// 	sx={{ width: 40, height: 40 }}
	// />
	// )

	// const logo = (
	// 	<Box sx={{ width: 40, height: 40, ...sx }}>
	// 		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
	// 			<g>
	// 				<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="166.2527" y1="353.277" x2="364.1739" y2="109.9503">
	// 					<stop offset="3.972550e-02" stopColor="#8FD2AB" />
	// 					<stop offset="0.1344" stopColor="#75B1C2" />
	// 					<stop offset="0.2372" stopColor="#5589DE" />
	// 					<stop offset="0.4655" stopColor="#753DB5" />
	// 					<stop offset="0.5246" stopColor="#7B39B2" />
	// 					<stop offset="0.6028" stopColor="#8C2DAA" />
	// 					<stop offset="0.6913" stopColor="#A7199D" />
	// 					<stop offset="0.7085" stopColor="#AD149A" />
	// 					<stop offset="0.7276" stopColor="#B91996" />
	// 					<stop offset="0.7794" stopColor="#D6248B" />
	// 					<stop offset="0.8246" stopColor="#E72B85" />
	// 					<stop offset="0.858" stopColor="#ED2D83" />
	// 				</linearGradient>
	// 				<path
	// 					fill="url(#SVGID_1_)"
	// 					d="M340,116.2h-80.1c-57,0-103.3,46.5-103.3,103.9v176.9H232c92.4,0,86.4-77.2,87.5-109.6v-88.7l-0.2-2.1
	// 						c0-7.2,5.8-13,12.9-13s12.9,5.8,12.9,13"
	// 				/>
	// 				<path
	// 					fill="url(#FFF)"
	// 					d="M229.1,397.1h-71.2c-64.4,0-119.3-52.2-119.3-116.5V172.6v-10.8c0-7.3-4.6-13.9-11.5-16.4l0,0l-11.5-4
	// 						c-1.6-0.6-2.7-2.1-2.7-3.7v0c0-2,1.5-3.6,3.4-3.9l131.4-18.7c3.1-0.6,6.3,0.6,8.3,3.1l0,0c1.2,1.5,1.8,3.3,1.8,5.2v176.9
	// 						C157.9,344.7,186.7,383.9,229.1,397.1L229.1,397.1z"
	// 				/>
	// 				<path
	// 					fill="url(#6CE000)"
	// 					d="M499,397.1v-5.4l-14.1-4.9l0,0c-6.9-2.5-11.5-9-11.5-16.4v-10.8V233.3c0-64.6-52.4-117-117-117h-81.1l0,0
	// 						c42.3,13.2,71.1,52.3,71.1,96.6v129.5c0,30.2,24.5,54.7,54.7,54.7H499z"
	// 				/>
	// 			</g>
	// 		</svg>
	// 	</Box>
	// )

	if (disabledLink) {
		return <>{logo}</>
	}

	return <RouterLink to="/">{logo}</RouterLink>
}
