import { createSlice, Dispatch } from '@reduxjs/toolkit'
import {
	getDatabase, ref, child, push, update, set, remove, onValue, query, Unsubscribe,
} from 'firebase/database'
import {
	arrayRemove, arrayUnion, doc, getFirestore, setDoc,
} from 'firebase/firestore'
import { FieldArray } from 'formik'
import { ConsoleLogger, logger } from 'src/services/logger'
import { IUserInterest, TInterestsState } from 'src/types/Interests'
import { TPersonalityState } from 'src/types/Personality'
// ----------------------------------------------------------------------

const initialState: TPersonalityState = {
	isLoading: false,
	isLoaded: false,
	error: false,
	allTraits: [
		'Funny',
		'Sincere',
		'Loving',
		'Kind',
		'Cheerful',
		'Creative',
		'Genius',
		'Gloomy',
		'Goofball',
		'High Maintenance',
		'Hot-Headed',
		'Romantic',
		'Self-Assured',
	],
	myTraits: [],
}

const slice = createSlice({
	name: 'personality-traits',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoaded = false
			state.isLoading = true
			state.error = false
		},

		// HAS ERROR
		getFailure(state) {
			state.isLoaded = false
			state.isLoading = false
			state.error = true
		},

		// GET INTERESTS
		getSuccess(state, action) {
			const { traits } = action.payload
			state.isLoaded = true
			state.isLoading = false
			state.myTraits = traits
			state.error = false
		},

		// Add INTEREST
		addTrait(state, action) {
			const { trait } = action.payload
			if (!state.myTraits.find((item) => item === trait)) {
				state.myTraits.push(trait)
			}
		},

		// Remove INTEREST
		removeTrait(state, action) {
			const { trait } = action.payload
			const idx = state.myTraits.findIndex((item) => item === trait)
			if (idx >= 0) {
				state.myTraits.splice(idx, 1)
			}
		},
	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface AddProps {
	trait: string
	uid: string
}

export function addPersonalityTrait(props: AddProps) {
	return async (dispatch: Dispatch) => {
		const { uid, trait } = props
		dispatch(slice.actions.addTrait({ trait }))

		const db = getFirestore()
		const docRef = doc(db, 'agent_configs', uid)

		await setDoc(docRef, {
			personality: arrayUnion(trait),
		}, { merge: true })
	}
}

// ----------------------------------------------------------------------

interface RemoveProps {
	uid: string
	trait: string
}
export function removePersonalityTrait(props: RemoveProps) {
	return async (dispatch: Dispatch) => {
		const { uid, trait } = props
		dispatch(slice.actions.removeTrait({ trait }))
		const db = getFirestore()
		logger.log({ uid })
		const docRef = doc(db, 'agent_configs', uid)

		await setDoc(docRef, {
			personality: arrayRemove(trait),
		}, { merge: true })
	}
}

// ----------------------------------------------------------------------
