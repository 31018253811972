import { useEffect, useLayoutEffect, useRef } from 'react'

function useInterval(callback: () => void, delay: number | null/* , otherDependecies:Array<any> */):any {
	const savedCallback = useRef(callback)

	// Remember the latest callback if it changes.
	useLayoutEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		// Don't schedule if no delay is specified.
		// Note: 0 is a valid value for delay.
		if (!delay && delay !== 0) {
			return () => {}
		}

		const id = setInterval(() => savedCallback.current(), delay)

		return () => clearInterval(id)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [delay/* , ...otherDependecies */])
}

export default useInterval
