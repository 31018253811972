import {
	DocumentData, DocumentSnapshot, FirestoreError, QuerySnapshot, Unsubscribe, collection, doc, getDoc, getFirestore, limit, onSnapshot, orderBy, query, where,
} from 'firebase/firestore'
import { ISpacePublicProfile } from 'src/@types/space'
import { FIRESTORE } from 'src/config'
import { TDecisionStatus } from 'src/types/DecisionHub'
import { TFeedItem } from 'src/types/Feed'
import { TThreadType } from 'src/types/Threads'
import { firestoreToFeedItem } from 'src/utils/feed'
import { firestoreToISpacePublicProfile } from 'src/utils/space'

interface FireStoreProps {
	snapshot: DocumentSnapshot<DocumentData, DocumentData>
}

interface GetFeedItemProps {
	feedItemId: string
	uid: string
}

export const getFeedItem = async ({ feedItemId, uid }: GetFeedItemProps): Promise<TFeedItem | undefined> => {
	const db = getFirestore()
	const docRef = doc(db, 'users', uid, 'feed_items', feedItemId)
	const documentSnapshot = await getDoc(docRef)
	if (documentSnapshot.exists()) {
		return firestoreToFeedItem(documentSnapshot)
	}
	return undefined
}

interface StreamFeedItemProps {
	feedItemId: string
	uid: string
	snapshot: (querySnapshot: DocumentSnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamFeedItem = ({
	feedItemId, uid, snapshot, error,
}: StreamFeedItemProps): Unsubscribe => {
	const db = getFirestore()
	const docRef = doc(db, 'users', uid, 'feed_items', feedItemId)
	return onSnapshot(docRef, snapshot, error)
}

// ----------------------------------------

// -----------------------------------------------------------------------------------

interface StreamFeedItemCommentsProps {
	rootFeedId: string
	snapshot: (querySnapshot: QuerySnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamFeedItemComments = ({ rootFeedId, snapshot, error }: StreamFeedItemCommentsProps): Unsubscribe => {
	const db = getFirestore()
	const collectionRef = collection(db, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEMS, rootFeedId, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEM_COMMENTS)
	const q = query(
		collectionRef,
		orderBy('createdAt', 'desc'),
	)
	return onSnapshot(q, snapshot, error)
}

// -----------------------------------------------------------------------------------
interface StreamThreadMessageProps {
	threadId: string
	snapshot: (querySnapshot: QuerySnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamThreadMessages = ({ threadId, snapshot, error }: StreamThreadMessageProps): Unsubscribe => {
	const db = getFirestore()
	const collectionRef = collection(db, 'threads', threadId, 'thread_messages')
	const q = query(
		collectionRef,
		orderBy('createdAt', 'asc'),
	)
	return onSnapshot(q, snapshot, error)
}

// -----------------------------------------------------------------------------------

interface StreamGiftThreadMessageProps {
	threadId: string
	snapshot: (querySnapshot: QuerySnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamGiftThreadMessages = ({ threadId, snapshot, error }: StreamGiftThreadMessageProps): Unsubscribe => {
	const db = getFirestore()
	const collectionRef = collection(db, 'gift_threads', threadId, 'thread_messages')
	const q = query(
		collectionRef,
		orderBy('createdAt', 'asc'),
	)
	return onSnapshot(q, snapshot, error)
}

// -----------------------------------------------------------------------------------

interface StreamStoryThreadMessageProps {
	threadId: string
	snapshot: (querySnapshot: QuerySnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamStoryThreadMessages = ({ threadId, snapshot, error }: StreamStoryThreadMessageProps): Unsubscribe => {
	const db = getFirestore()
	const collectionRef = collection(db, 'story_threads', threadId, 'thread_messages')
	const q = query(
		collectionRef,
		orderBy('createdAt', 'asc'),
	)
	return onSnapshot(q, snapshot, error)
}

interface StreamPresenceProps {
	threadId: string
	snapshot: (querySnapshot: QuerySnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamPresence = ({ threadId, snapshot, error }: StreamPresenceProps): Unsubscribe => {
	const db = getFirestore()
	const collectionRef = collection(db, 'threads', threadId, 'presence')
	return onSnapshot(collectionRef, snapshot, error)
}

interface StreamViewingStatsProps {
	threadId: string
	uid: string
	snapshot: (querySnapshot: DocumentSnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamViewingStats = ({
	threadId, uid, snapshot, error,
}: StreamViewingStatsProps): Unsubscribe => {
	const db = getFirestore()
	const docRef = doc(db, 'threads', threadId, 'viewing_stats', uid)
	return onSnapshot(docRef, snapshot, error)
}

// ---------------------------------------

interface StreamUserStatusProps {
	uid: string
	snapshot: (querySnapshot: DocumentSnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamUserStatus = ({
	uid, snapshot, error,
}: StreamUserStatusProps): Unsubscribe => {
	const db = getFirestore()
	const docRef = doc(db, 'world_status_user', uid)
	return onSnapshot(docRef, snapshot, error)
}

// ----------------------------------------

interface StreamAgentConfigProps {
	uid: string
	snapshot: (querySnapshot: DocumentSnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamAgentConfig = ({
	uid, snapshot, error,
}: StreamAgentConfigProps): Unsubscribe => {
	const db = getFirestore()
	const docRef = doc(db, 'agent_configs', uid)
	return onSnapshot(docRef, snapshot, error)
}

// ----------------------------------------

interface StreamThreadProps {
	threadId: string
	snapshot: (querySnapshot: DocumentSnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamThread = ({
	threadId, snapshot, error,
}: StreamThreadProps): Unsubscribe => {
	const db = getFirestore()
	const docRef = doc(db, 'threads', threadId)
	return onSnapshot(docRef, snapshot, error)
}

// ----------------------------------------

interface StreamGiftThreadProps {
	threadId: string
	snapshot: (querySnapshot: DocumentSnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamGiftThread = ({
	threadId, snapshot, error,
}: StreamGiftThreadProps): Unsubscribe => {
	const db = getFirestore()
	const docRef = doc(db, 'gift_threads', threadId)
	return onSnapshot(docRef, snapshot, error)
}

// ----------------------------------------
interface StreamStoryThreadProps {
	threadId: string
	snapshot: (querySnapshot: DocumentSnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamStoryThread = ({
	threadId, snapshot, error,
}: StreamStoryThreadProps): Unsubscribe => {
	const db = getFirestore()
	const docRef = doc(db, 'story_threads', threadId)
	return onSnapshot(docRef, snapshot, error)
}

// ----------------------------------------

// ----------------------------------------

// interface StreamChatThreadProps {
// 	parentThreadId: string
// 	snapshot: (querySnapshot: QuerySnapshot<DocumentData, DocumentData>) => void
// 	error: (error: FirestoreError) => void
// }
// export const streamChatThread = ({
// 	parentThreadId, snapshot, error,
// }: StreamChatThreadProps): Unsubscribe => {
// 	const db = getFirestore()
// 	const collectionRef = collection(db, 'threads')

// 	/**
// 	 * this should get the most recent chat thread
// 	 */
// 	const q = query(
// 		collectionRef,
// 		orderBy('createdAt', 'desc'),
// 		where('threadType', '==', <TThreadType>'CHAT'),
// 		where('parentThreadIds', 'array-contains', parentThreadId),
// 		where('hasEnded', '==', false),
// 		limit(1),
// 	)

// 	return onSnapshot(q, snapshot, error)
// }

// // ----------------------------------------

// interface StreamChatThreadProps {
// 	parentThreadId: string
// 	snapshot: (querySnapshot: QuerySnapshot<DocumentData, DocumentData>) => void
// 	error: (error: FirestoreError) => void
// }
// export const streamChatThread = ({
// 	parentThreadId, snapshot, error,
// }: StreamChatThreadProps): Unsubscribe => {
// 	const db = getFirestore()
// 	const collectionRef = collection(db, 'threads')

// 	/**
// 	 * this should get the most recent chat thread
// 	 */
// 	const q = query(
// 		collectionRef,
// 		orderBy('createdAt', 'desc'),
// 		where('threadType', '==', <TThreadType>'CHAT'),
// 		where('parentThreadIds', 'array-contains', parentThreadId),
// 		where('hasEnded', '==', false),
// 		limit(1),
// 	)

// 	return onSnapshot(q, snapshot, error)
// }

// ----------------------------------------

interface StreamThreadActivitiesProps {
	threadId: string
	snapshot: (querySnapshot: QuerySnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamThreadActivities = ({ threadId, snapshot, error }: StreamThreadActivitiesProps): Unsubscribe => {
	const db = getFirestore()
	const collectionRef = collection(db, 'threads', threadId, 'thread_activities')
	const q = query(
		collectionRef,
		orderBy('createdAt', 'asc'),
	)
	return onSnapshot(q, snapshot, error)
}

// ------------------------------------------

interface GetSpaceProps {
	spaceId: string
}

export const getSpace = async ({ spaceId }: GetSpaceProps): Promise<ISpacePublicProfile | undefined> => {
	const db = getFirestore()
	const docRef = doc(db, 'space_public_profiles', spaceId)
	const documentSnapshot = await getDoc(docRef)
	if (documentSnapshot.exists()) {
		return firestoreToISpacePublicProfile(documentSnapshot)
	}
	return undefined
}

// ---------------------------------------

interface StreamSpaceOccupantsProps {
	spaceId: string
	snapshot: (querySnapshot: QuerySnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamSpaceOccupants = ({
	spaceId, snapshot, error,
}: StreamSpaceOccupantsProps): Unsubscribe => {
	const db = getFirestore()
	const collectionRef = collection(db, 'space_status', spaceId, 'space_occupants')

	const q = query(
		collectionRef,
	)

	return onSnapshot(q, snapshot, error)
}

// ----------------------------------------

interface StreamDecisionHubProps {
	uid: string
	snapshot: (querySnapshot: QuerySnapshot<DocumentData, DocumentData>) => void
	error: (error: FirestoreError) => void
}
export const streamDecisionHub = ({ uid, snapshot, error }: StreamDecisionHubProps): Unsubscribe => {
	const db = getFirestore()
	const collectionRef = collection(db, 'decision_hub', uid, 'decision_nodes')
	const q = query(
		collectionRef,
		orderBy('createdAt', 'asc'),
		where('status', '==', <TDecisionStatus>'PENDING'),
	)
	return onSnapshot(q, snapshot, error)
}
