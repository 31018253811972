import { IPromptSuggestionTopic } from 'src/types/PromptSuggestions'

export const defaultPromptSuggesstionTopic: IPromptSuggestionTopic = {
	label: 'Quick Actions',
	query: 'quick_actions',
}

export const allPromptSuggesstionTopics: Array<IPromptSuggestionTopic> = [
	{
		label: 'Zombie Apocalyse',
		value: 'because there is a zombie apocalypse',
		preface: 'OMG, there\'s a zombie apocalpyse!!!',
		query: 'suggestions',
	},
	// {
	// 	label: 'Vampires',
	// 	value: 'because {{ target }} is really into vampires',
	// 	query: 'suggestions',
	// },
	{
		label: 'Silly Questions',
		value: 'robots on steroids',
		query: 'ask',
	},
	{
		label: 'Disasters',
		value: 'because an earthquake is occuring',
		query: 'suggestions',
	},
	{
		label: 'Superheroes',
		value: 'because we are superheroes',
		preface: 'We are superheroes!',
		query: 'suggestions',
	},
]
