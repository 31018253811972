
import React, {
	createContext,
	ReactNode,
	useMemo,
} from 'react'
import mixpanel from 'mixpanel-browser'
import { TMixpanelContext } from '../@types/mixpanel'

const token = process.env.REACT_APP_MIXPANEL_TOKEN

const MixpanelContext = createContext<TMixpanelContext | null>(null)

function MixpanelProvider({ children }: { children: ReactNode }) {
	if (token) {
		mixpanel.init(token, {
			debug: ((process.env.NODE_ENV !== 'production') || (process.env.REACT_APP_IS_TEST_ENV === 'true')),
			// debug: false,
		})
		mixpanel.register_once({
			env: process.env.REACT_APP_IS_TEST_ENV === 'true' ? 'test' : process.env.NODE_ENV,
		})
	}

	const value = useMemo(() => ({
		mixpanel,
	} as TMixpanelContext), [])

	return (
		<MixpanelContext.Provider
			value={value}
		>
			{children}
		</MixpanelContext.Provider>
	)
}

export { MixpanelContext, MixpanelProvider }
