import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { ConsoleLogger, logger } from 'src/services/logger'
import { TPeopleFriendsState } from 'src/types/PeopleFriends'
import {
	collection, collectionGroup, getDocs, getFirestore, query, where,
} from 'firebase/firestore'
import { compact, intersection } from 'lodash'
import { TPeopleFollowingState } from 'src/types/PeopleFollowing'

// ----------------------------------------------------------------------

type TSuccessPayload = {
	followingUids: Array<string>
	uid: string
}

type TStartLoadingPayload = {
	uid: string
}

type TFailureLoadingPayload = {
	uid: string
}

const initialState: TPeopleFollowingState = {
	byUid: {},
}

const slice = createSlice({
	name: 'people-following',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state, action) {
			const { uid }: TStartLoadingPayload = action.payload
			state.byUid[uid] = {
				status: {
					isLoading: true,
					isLoaded: false,
					error: false,
				},
				followingUids: [],
			}
		},

		// HAS ERROR
		getFailure(state, action) {
			const { uid }: TFailureLoadingPayload = action.payload
			state.byUid[uid] = {
				status: {
					isLoading: false,
					isLoaded: true,
					error: true,
				},
				followingUids: [],
			}
		},

		getSuccess(state, action) {
			const { followingUids, uid }: TSuccessPayload = action.payload
			logger.log({ followingUids })
			state.byUid[uid] = {
				status: {
					isLoading: false,
					isLoaded: true,
					error: false,
				},
				followingUids,
			}
		},

	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface GetFollowingOfPersonProps {
	otherPersonUid: string
}

export function getFollowingOfPerson(props: GetFollowingOfPersonProps) {
	return async (dispatch: Dispatch) => {
		const { otherPersonUid: uid } = props
		dispatch(slice.actions.startLoading({ uid }))
		try {
			logger.log('getFollowingOfPerson=', props)

			// get everyone this person follows
			const db = getFirestore()
			const collectionRef = collection(db, 'users', uid, 'following')
			const q1 = query(collectionRef)

			const [querySnapshotOfFollowing] = await Promise.all([
				getDocs(q1),
			])

			const followingUids = querySnapshotOfFollowing.docs.map((doc) => doc.id)

			dispatch(slice.actions.getSuccess({
				uid,
				followingUids,
			}))
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.getFailure({ uid }))
		}
	}
}
