import { createSlice } from '@reduxjs/toolkit'
import { IBadge, TBadgeState } from 'src/types/Badges'

// ----------------------------------------------------------------------

const badges:Array<IBadge> = [
	{
		id: '1',
		description: 'Newbie',
	},
	{
		id: '2',
		description: 'Crunked',
	},
	{
		id: '3',
		description: 'Dog Lover',
	},
	{
		id: '4',
		description: 'Gossip Girl',
	},
	{
		id: '5',
		description: 'Deviant',
	},
	{
		id: '6',
		description: 'Public Menance',
	},
	{
		id: '7',
		description: 'Adventurer',
	},
	{
		id: '8',
		description: 'Superhero',
	},
]

const initialState: TBadgeState = {
	badges,
	myBadgesByBadgeId: {},
}

const slice = createSlice({
	name: 'badges',
	initialState,
	reducers: {},
})

// Reducer
export default slice.reducer
