import {
	ComponentType, ElementType, lazy as originalLazy,
} from 'react'

// export function componentLoader(lazyComponent: React.LazyExoticComponent<() => JSX.Element>, attemptsLeft: number) {
// 	return new Promise((resolve, reject) => {
// 		lazyComponent()
// 			.then(resolve)
// 			.catch((error) => {
// 				// let us retry after 1500 ms
// 				setTimeout(() => {
// 					if (attemptsLeft === 1) {
// 						reject(error)
// 						return
// 					}
// 					componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject)
// 				}, 1500)
// 			})
// 	})
// }

// a function to retry loading a chunk to avoid chunk load error for out of date code
/* export async function lazyRetry(componentImport: any, name: string):Promise<{ default: ComponentType<any> }> {
	return new Promise((resolve, reject) => {
		// check if the window has already been refreshed
		const hasRefreshed = JSON.parse(
			window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false',
		)
		// try to import the component
		componentImport().then((component: { default: ComponentType<any> }) => {
			window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false') // success so reset the refresh
			resolve(component)
		// eslint-disable-next-line consistent-return
		}).catch((error:any) => {
			if (!hasRefreshed) { // not been refreshed yet
				window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true') // we are now going to refresh
				return window.location.reload() // refresh the page
			}
			reject(error) // Default error behaviour as already tried refresh
		})
	})
} */

type ImportComponent = () => Promise<{ default: ComponentType }>;

export const lazy = (importComponent: ImportComponent) => originalLazy((async () => {
	const isPageHasBeenForceRefreshed = JSON.parse(
		localStorage.getItem('page-has-been-force-refreshed') || 'false',
	)

	try {
		const component = await importComponent()

		localStorage.setItem('page-has-been-force-refreshed', 'false')

		return component
	} catch (error) {
		if (!isPageHasBeenForceRefreshed) {
			localStorage.setItem('page-has-been-force-refreshed', 'true')
			return window.location.reload()
		}

		throw error
	}
}) as ImportComponent)
