// ----------------------------------------------------------------------

const breakpoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		tabletSmall: 1024,
		tabletLarge: 1180,
		lg: 1200,
		xl: 1536,
	},
}

export default breakpoints
