// routes
import { RouterProvider } from 'react-router'
// import LinkedInTag from 'react-linkedin-insight'
import { router } from './routes'

// ----------------------------------------------------------------------

const App = () => <RouterProvider router={router} />

export default App
