import { createSlice } from '@reduxjs/toolkit'
import { logger } from 'src/services/logger'
import { IThreadMessage, TThreadMessagesState } from 'src/types/ThreadMessages'
// import {
// 	getDatabase, onValue, ref, Unsubscribe as RtdbUnsubscribe,
// } from 'firebase/database'
// import { rtdbToIThreadMessage } from 'src/utils/threads'
import {
	Unsubscribe, collection, getDocs, getFirestore, onSnapshot,
} from 'firebase/firestore'
import { firestoreToIThreadMessage } from 'src/utils/threads'
import { error } from 'console'
import * as FirestoreService from 'src/services/FirestoreService'
import { compact } from 'lodash'
import { dispatch } from '../store'
// ----------------------------------------------------------------------

// type TSetChoicePayload = {
// 	id: string
// 	status: 'PENDING' | 'COMPLETE' | 'IGNORED'
// 	selectedChoice: TThreadChoiceWithConfig
// }

type TSuccessPayload = {
	messages: Array<IThreadMessage>
	threadId: string
}

type TStartLoadingPayload = {
	threadId: string
}

const initialState: TThreadMessagesState = {
	messagesByThreadId: {},
	// isLoaded: false,
	// isLoading: false,
}

const slice = createSlice({
	name: 'thread-messages',
	initialState,
	reducers: {

		startLoading(state, action) {
			const { threadId }:TStartLoadingPayload = action.payload

			state.messagesByThreadId = {
				...state.messagesByThreadId,
				[threadId]: {
					messages: [],
					isLoading: true,
					isLoaded: false,
				},
			}
		},

		getSuccess(state, action) {
			const { messages, threadId }:TSuccessPayload = action.payload

			state.messagesByThreadId = {
				...state.messagesByThreadId,
				[threadId]: {
					messages,
					isLoaded: true,
					isLoading: false,
				},
			}
		},

	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface GetThreadMessagesProps {
	threadId: string
}

export const listenForThreadMessages = ({ threadId }:GetThreadMessagesProps) => (): Unsubscribe => {
	logger.log('listenForThreadMessages')
	dispatch(slice.actions.startLoading({
		threadId,
	}))

	const unsubscribe = FirestoreService.streamThreadMessages({
		threadId,
		snapshot: (querySnapshot) => {
			const messages:Array<IThreadMessage> = compact(querySnapshot.docs.map((doc) => {
				if (doc.data()?.createdAt) {
					return firestoreToIThreadMessage(doc)
				}
				return null
			}))
			dispatch(slice.actions.getSuccess({
				messages,
				threadId,
			}))
		},
		error: (error) => logger.error(error),
	})
	return unsubscribe
}
