import { createSlice, Dispatch } from '@reduxjs/toolkit'

import { logger } from 'src/services/logger'
import { TPeopleInterestsState } from 'src/types/PeopleInterests'
import { IUserInterest } from 'src/types/Interests'
import {
	get, getDatabase, onValue, ref,
} from 'firebase/database'

// ----------------------------------------------------------------------

type TSuccessPayload = {
	interests: Array<IUserInterest>
	uid: string
}

type TStartLoadingPayload = {
	uid: string
}

type TFailureLoadingPayload = {
	uid: string
}

const initialState: TPeopleInterestsState = {
	interestsByUid: {},
}

const slice = createSlice({
	name: 'people-interests',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state, action) {
			const { uid }: TStartLoadingPayload = action.payload
			state.interestsByUid[uid] = {
				status: {
					isLoading: true,
					isLoaded: false,
					error: false,
				},
				interests: [],
			}
		},

		// HAS ERROR
		getFailure(state, action) {
			const { uid }: TFailureLoadingPayload = action.payload
			state.interestsByUid[uid] = {
				status: {
					isLoading: false,
					isLoaded: true,
					error: true,
				},
				interests: [],
			}
		},

		// GET PROMOTIONS
		getSuccess(state, action) {
			const { interests, uid }: TSuccessPayload = action.payload
			state.interestsByUid[uid] = {
				status: {
					isLoading: false,
					isLoaded: true,
					error: false,
				},
				interests,
			}
		},

	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface GetInterestsProps {
	uid: string
}

export function getInterests(props: GetInterestsProps) {
	return async (dispatch: Dispatch) => {
		const { uid } = props
		dispatch(slice.actions.startLoading({ uid }))
		try {
			const interests:Array<IUserInterest> = []
			logger.log('getInterests for other people=', props)
			const db = getDatabase()
			const collectionRef = ref(db, `users/${uid}/interests`)
			const snapshot = await get(collectionRef)

			if (snapshot.exists()) {
				snapshot.forEach((childSnapshot) => {
					// const childKey = childSnapshot.key
					const interest: IUserInterest = childSnapshot.val() as IUserInterest
					interests.push(interest)
				})
			}

			dispatch(slice.actions.getSuccess({
				uid,
				interests,
			}))
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.getFailure({ uid }))
		}
	}
}
