import { Howl } from 'howler'

export interface ISoundEffect {
	file: string
	// howl?: Howl
	player: HTMLAudioElement
	soundId?: any
	loop?: boolean
	label?: string
}

export interface ISoundFile {
	file: string
}

export interface IStreamingMusic {
	file: string
	label: string
}

export type TStreamingSoundName =
| 'bkgMusicHappy'
| 'bkgMusicUpBeat'
| 'bkgMusicDisco'
| 'bkgMusicDogsOut'
| 'bkgMusicEpic'
| 'bkgMusicHeavy'
| 'bkgMusicIndie'
| 'bkgMusicWinnerEpic'
| 'bkgMusicUpBeatFunSports'
| 'bkgMusicJazz'
| 'bkgMusicAcousticUpbeat'
| 'bkgMusicContemplation'

export const allStreamingSounds: Record<TStreamingSoundName, IStreamingMusic> = {
	bkgMusicHappy: {
		file: 'lounge party',
		label: 'Happy',
	},
	bkgMusicUpBeat: {
		file: 'Modern Abstract Design Logo',
		label: 'Upbeat',
	},
	bkgMusicDisco: {
		file: 'Logo MP3',
		label: 'Disco',
	},
	bkgMusicDogsOut: {
		file: 'Baha-Men-Who-Let-The-Dogs-Out-Na',
		label: 'Dogs Out',
	},
	bkgMusicEpic: {
		file: 'Cinematic Epic Trailer Logo',
		label: 'Epic',
	},
	bkgMusicHeavy: {
		file: 'Heavy Metal Road Chase Ident',
		label: 'Heavy Metal',
	},
	bkgMusicIndie: {
		file: 'Upbeat Indie Rock - 30',
		label: 'Indie',
	},
	bkgMusicWinnerEpic: {
		file: 'Winner Epic Logo MP3',
		label: 'Winner',
	},
	bkgMusicUpBeatFunSports: {
		file: 'Upbeat & Fun Sports Rock Ident',
		label: 'Fun',
	},
	bkgMusicJazz: {
		file: 'Positive Jazz Logo MP3',
		label: 'Jazz',
	},
	bkgMusicAcousticUpbeat: {
		file: 'Acoustic Upbeat',
		label: 'Acoustic Upbeat',
	},
	bkgMusicContemplation: {
		file: 'Contemplation',
		label: 'Contemplation',
	},
	// bkgMusicScooby: {
	// 	file: 'DJ-Kass-Scooby-Doo-Pa-Pa-via-Naijafinix.com_-1',
	// 	label: 'Scooby',
	// },
	// bkgMusicKissMe: {
	// 	file: 'Kiss-Me-More---Doja-',
	// 	label: 'Kiss Me',
	// },
	// bkgMusicLatin: {
	// 	file: 'Latin Soft Lounge_medium',
	// 	label: 'Latin',
	// },
	// bkgMusicPower: {
	// 	file: 'Powerful Driving Hip Hop Guitar',
	// 	label: 'Power Hip Hop',
	// },
}

export const getFilePath = (fileName: string):string => `/assets/demo/sound/${fileName}.mp3`

export const allMusic = ():Array<{label:string;value:string}> => Object.entries(allStreamingSounds).map(([key, soundEffect]) => ({
	label: soundEffect.label,
	value: key,
}))

const subfolder = 'unrealish/'

const allSpeakingFiles:Record<string, ISoundFile> = {
	a: {
		file: `${subfolder}a.mp3`,
	},
	b: {
		file: `${subfolder}b.mp3`,
	},
	c: {
		file: `${subfolder}c.mp3`,
	},
	d: {
		file: `${subfolder}d.mp3`,
	},
	e: {
		file: `${subfolder}e.mp3`,
	},
	f: {
		file: `${subfolder}f.mp3`,
	},
	g: {
		file: `${subfolder}g.mp3`,
	},
	h: {
		file: `${subfolder}h.mp3`,
	},
	i: {
		file: `${subfolder}i.mp3`,
	},
	j: {
		file: `${subfolder}j.mp3`,
	},
	k: {
		file: `${subfolder}k.mp3`,
	},
	l: {
		file: `${subfolder}l.wav`,
	},
	m: {
		file: `${subfolder}m.wav`,
	},
	n: {
		file: `${subfolder}n.wav`,
	},
	o: {
		file: `${subfolder}o.mp3`,
	},
	p: {
		file: `${subfolder}p.wav`,
	},
	q: {
		file: `${subfolder}q.wav`,
	},
	r: {
		file: `${subfolder}r.wav`,
	},
	s: {
		file: `${subfolder}s.wav`,
	},
	t: {
		file: `${subfolder}t.wav`,
	},
	u: {
		file: `${subfolder}u.mp3`,
	},
	// v: files.v,
	// w: files.w,
	// x: files.x,
	y: {
		file: `${subfolder}y.mp3`,
	},
	// z: files.z,
}

export type TSound =
| 'bkgMusicHappy'
| 'coinsound'
| 'Fart1'
| 'Growl1'
| 'Growl2'
| 'Growl3'
| 'Growl4'
| 'Growl5'
| 'Growl6'
| 'Kick1'
| 'Kiss1'
| 'pop'
| 'pop-up-on'
| 'pop3'
| 'pop4'
| 'Poke1'
| 'Punch1'
| 'Punch2'
| 'Punch3'
| 'Punch4'
| 'Shine1'
| 'Snore1'
| 'Squash'
| 'Spank1'
| 'swipe'
| 'Tickle1'
| 'Tickle2'
| 'Tickle3'
| 'Tickle4'
| 'Voodoo1'
| 'Yawn1'
| 'Yawn2'
| 'Yawn3'

export const allPreloadSounds: Record<string, ISoundFile> = {
	pop: {
		file: 'pop-down.mp3',
	},
	'pop-up-on': {
		file: 'pop-up-on.mp3',
	},
	pop3: {
		file: 'pop-up-off.mp3',
	},
	pop4: {
		file: 'mixkit-game-ball-tap-2073.mp3',
	},
	// Fart1: {
	// 	file: 'ES_Fart Wet 1 - SFX Producer',
	// },
	coinsound: {
		file: 'coinsound.mp3',
	},
	// Growl1: {
	// 	file: 'zapsplat_horror_monster_agressive_short_growl_54463',
	// },
	// Growl2: {
	// 	file: 'zapsplat_horror_monster_agressive_short_growl_throaty_54462',
	// },
	// Growl3: {
	// 	file: 'zapsplat_horror_yeti_monster_growl_snarl_006_41795',
	// },
	// Growl4: {
	// 	file: 'zapsplat_horror_yeti_monster_growl_snarl_008_41797',
	// },
	// Growl5: {
	// 	file: 'animals_bear_growl_002',
	// },
	// Growl6: {
	// 	file: 'animals_bear_growl_001',
	// },
	// Kick1: {
	// 	file: 'Kick',
	// },
	// Kiss1: {
	// 	file: 'Kiss',
	// },
	// Poke1: {
	// 	file: 'poke',
	// },
	// Punch1: {
	// 	file: 'zapsplat_impact_boxing_glove_punch_hit_body_002_79322',
	// },
	// Punch2: {
	// 	file: 'ES_Punch Face 4 - SFX Producer',
	// },
	// Punch3: {
	// 	file: 'ES_Punch Face 5 - SFX Producer',
	// },
	// Punch4: {
	// 	file: 'ES_Punch Fist 3 - SFX Producer',
	// },
	// Snore1: {
	// 	file: 'Elf Sleep Snoring 2',
	// },
	// swipe: {
	// 	file: 'swipe',
	// },
	// Shine1: {
	// 	file: 'shine',
	// },
	// Squash: {
	// 	file: 'squash',
	// },
	// Spank1: {
	// 	file: 'spank',
	// },
	// Tickle1: {
	// 	file: '20205 male tickling giggle-full',
	// },
	// Tickle2: {
	// 	file: 'zapsplat_cartoon_character_cute_high_pitched_giggle_laugh_short_004_38444',
	// },
	// Tickle3: {
	// 	file: 'zapsplat_cartoon_character_cute_high_pitched_giggle_laugh_short_005_38445',
	// },
	// Tickle4: {
	// 	file: 'zapsplat_cartoon_character_cute_high_pitched_giggle_laugh_short_006_38446',
	// },
	// Voodoo1: {
	// 	file: 'Dark_Game_Voice_Spell_Innotum_Sy_Lumani_3_Group_Chant_Focused_Echo_Summon_Male',
	// },
	// Yawn1: {
	// 	file: 'Yawn',
	// },
	// Yawn2: {
	// 	file: 'ES_Yawn Male 2 - SFX Producer',
	// },
	// Yawn3: {
	// 	file: 'ES_Yawn Male 1 - SFX Producer',
	// },
	...allSpeakingFiles,
}
