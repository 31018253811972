import { map, filter } from 'lodash'
import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { getFunctions, httpsCallable } from 'firebase/functions'
// import { TokenLoadingStatus } from 'src/@types/token'
import {
	doc, getDoc, getFirestore, onSnapshot, query,
	increment, serverTimestamp, setDoc, Timestamp, collection, getDocs, where, addDoc, Unsubscribe,
} from 'firebase/firestore'

import { ConsoleLogger, logger } from 'src/services/logger'
import { dispatch } from '../store'
// utils
import axios from '../../utils/axios'
import {
	Friend,
	Gallery,
	Profile,
	UserPost,
	Follower,
	UserData,
	CreditCard,
	UserInvoice,
	UserManager,
	UserAddressBook,
	NotificationSettings,
} from '../../@types/user'

// ----------------------------------------------------------------------

export type TWalletLoadingStatus = 'SUCCESS' | 'RUNNING' | 'FAILURE';
export type TImportWalletError = 'INCORRECT OWNERSHIP' | 'NOT VERIFIED';

type UserState = {
	currentlyImportingWalletId: null | string
  isLoading: boolean
	isSaving: boolean
	isLoadingCustomTokens: boolean
	// isImportingWallet: boolean
	isLoadingWallets: boolean
	isLoadingLikedStories: boolean
	isLoadingPoints: boolean
	isLoadingFollowing: boolean
	error: boolean
	errorLoadingCustomTokens: boolean
	// errorImportWallet: TImportWalletError | null
	// errorImportingWallet: boolean
	// importWalletStatus: {
  //   byId: Record<string, TWalletLoadingStatus>
  // }
  myProfile: null | Profile
  posts: UserPost[]
  users: UserData[]
  userList: UserManager[]
  followers: Follower[]
	// customTokens: Array<ICustomTokenPfpResponse>
  friends: Friend[]
  gallery: Gallery[]
  cards: CreditCard[] | null
  addressBook: UserAddressBook[]
  invoices: UserInvoice[]
  notifications: NotificationSettings | null
	// customTokensMetadataLoadingStatus: {
  //   byId: Record<string, TokenLoadingStatus>
  // }
	// wallets: Array<TWallet>
	likedStoryIds: Array<string>
	followingCustomTokenIds: Array<string>
	xp: number | null
	isLoggedIn: boolean
};

const initialState: UserState = {
	currentlyImportingWalletId: null,
	isLoading: false,
	isSaving: false,
	isLoadingCustomTokens: false,
	// isImportingWallet: false,
	isLoadingWallets: false,
	isLoadingLikedStories: false,
	isLoadingPoints: false,
	isLoadingFollowing: false,
	error: false,
	errorLoadingCustomTokens: false,
	// errorImportWallet: null,
	// importWalletStatus: {
	// 	byId: {},
	// },
	myProfile: null,
	posts: [],
	users: [],
	userList: [],
	followers: [],
	friends: [],
	gallery: [],
	cards: null,
	addressBook: [],
	invoices: [],
	likedStoryIds: [],
	followingCustomTokenIds: [],
	notifications: null,
	// customTokensMetadataLoadingStatus: {
	// 	byId: {},
	// },
	// wallets: [],
	xp: null,
	isLoggedIn: false,
}

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {

		// START SAVING
		startSaving(state) {
			state.isSaving = true
		},

		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// START LOADING
		startLoadingWallets(state) {
			state.isLoadingWallets = true
		},

		startLoadingCustomTokens(state) {
			state.isLoadingCustomTokens = true
			state.errorLoadingCustomTokens = false
		},

		// START IMPORTING WALLET
		// startImportingWallet(state) {
		// 	state.isImportingWallet = true
		// 	state.errorImportWallet = null
		// },

		// startImportingWallet(state, action) {
		// 	const { walletId, importStatus }:{walletId: string; importStatus: TWalletLoadingStatus} = action.payload
		// 	state.currentlyImportingWalletId = walletId
		// 	state.importWalletStatus.byId[walletId] = importStatus
		// 	state.errorImportWallet = null
		// },

		startLoadingLikedStories(state) {
			state.isLoadingLikedStories = true
		},

		startLoadingFollowing(state) {
			state.isLoadingFollowing = true
		},

		startLoadingPoints(state) {
			state.isLoadingPoints = true
		},

		// START IMPORTING WALLET
		// endImportingWallet(state) {
		// 	state.isImportingWallet = false
		// },

		// HAS ERROR IMPORTING WALLET
		// importWalletFailure(state) {
		// 	// state.isImportingWallet = false
		// 	// state.errorImportingWallet = true
		// 	state.importWalletStatus = 'FAILURE'
		// },

		// IMPORT WALLET SUCCESS
		// importWalletStatusUpdate(state, action) {
		// 	// state.isImportingWallet = false
		// 	// state.errorImportingWallet = false
		// 	const { walletId, importStatus } = action.payload
		// 	state.importWalletStatus.byId[walletId] = importStatus
		// 	if (importStatus !== 'RUNNING') {
		// 		state.currentlyImportingWalletId = null
		// 	}
		// },

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = true
		},

		// HAS ERROR LOADING CUSTOM TOKENS
		hasErrorLoadingCustomTokens(state, action) {
			state.isLoadingCustomTokens = false
			state.errorLoadingCustomTokens = true
		},

		// hasErrorImportingWallet(state, action) {
		// 	const { reason }: {reason: TImportWalletError} = action.payload
		// 	state.errorImportWallet = reason
		// 	state.currentlyImportingWalletId = null
		// },

		// GET PROFILE
		getProfileSuccess(state, action) {
			state.isLoading = false
			state.myProfile = action.payload
		},

		// GET POSTS
		getPostsSuccess(state, action) {
			state.isLoading = false
			state.posts = action.payload
		},

		// GET USERS
		getUsersSuccess(state, action) {
			state.isLoading = false
			state.users = action.payload
		},

		// DELETE USERS
		deleteUser(state, action) {
			const deleteUser = filter(state.userList, (user) => user.id !== action.payload)
			state.userList = deleteUser
		},

		// GET FOLLOWERS
		getFollowersSuccess(state, action) {
			state.isLoading = false
			state.followers = action.payload
		},

		// GET WALLETS
		// getWalletsSuccess(state, action) {
		// 	const { wallets } = action.payload
		// 	state.wallets = wallets
		// },

		// ON TOGGLE FOLLOW
		onToggleFollow(state, action) {
			const followerId = action.payload

			const handleToggle = map(state.followers, (follower) => {
				if (follower.id === followerId) {
					return {
						...follower,
						isFollowed: !follower.isFollowed,
					}
				}
				return follower
			})

			state.followers = handleToggle
		},

		// GET FRIENDS
		getFriendsSuccess(state, action) {
			state.isLoading = false
			state.friends = action.payload
		},

		// GET PFPS
		// getPfpsSuccess(state, action) {
		// 	state.customTokens = action.payload
		// 	state.isLoadingCustomTokens = false
		// },

		// GET GALLERY
		getGallerySuccess(state, action) {
			state.isLoading = false
			state.gallery = action.payload
		},

		// GET MANAGE USERS
		getUserListSuccess(state, action) {
			state.isLoading = false
			state.userList = action.payload
		},

		// GET CARDS
		getCardsSuccess(state, action) {
			state.isLoading = false
			state.cards = action.payload
		},

		// GET ADDRESS BOOK
		getAddressBookSuccess(state, action) {
			state.isLoading = false
			state.addressBook = action.payload
		},

		// GET INVOICES
		getInvoicesSuccess(state, action) {
			state.isLoading = false
			state.invoices = action.payload
		},

		// GET NOTIFICATIONS
		getNotificationsSuccess(state, action) {
			state.isLoading = false
			state.notifications = action.payload
		},

		// START LOADING METADATA
		// startLoadingMetadata(state, action) {
		// 	const customTokenId = action.payload
		// 	state.customTokensMetadataLoadingStatus.byId[customTokenId] = 'RUNNING'
		// },

		// REFRESH METADATA SUCCESS
		// refreshMetadataSuccess(state, action) {
		// 	const { customTokenId, customToken }: {customTokenId: string; customToken: ICustomTokenPfpResponse } = action.payload
		// 	const index: number = state.customTokens.findIndex((item) => item.customTokenId === customTokenId)
		// 	// console.log(customToken)
		// 	// state.customTokens[index] = customToken
		// 	state.customTokensMetadataLoadingStatus.byId[customTokenId] = 'SUCCESS'
		// },

		// REFRESH METADATA FAILURE
		// refreshMetadataFailure(state, action) {
		// 	const { customTokenId }: {customTokenId: string} = action.payload
		// 	state.customTokensMetadataLoadingStatus.byId[customTokenId] = 'FAILURE'
		// },

		// // UPDATE PFP SUCCESS
		updatePfpSuccess(state, action) {
			// const { customTokenId, customToken }: {customTokenId: string; customToken: Partial<ICustomTokenPfpResponse> } = action.payload
			// const index: number = state.customTokens.findIndex((item) => item.customTokenId === customTokenId)
			// // console.log(customToken)
			// // state.customTokens[index] = customToken
			// state.customTokens[index] = {
			// 	...state.customTokens[index],
			// 	...customToken,
			// }
			state.isSaving = false
		},

		// UPDATE PFP SUCCESS
		// addWalletSuccess(state, action) {
		// 	const { wallet }: {wallet:TWallet } = action.payload
		// 	const index: number = state.wallets.findIndex((item) => item.id === wallet.id)
		// 	if (index === -1) {
		// 		state.wallets = [...state.wallets, wallet]
		// 	}
		// },

		getLikedStoriesSuccess(state, action) {
			const { storyIds } = action.payload
			state.likedStoryIds = storyIds
			state.isLoadingLikedStories = false
		},

		getLikedStoriesFailure(state) {
			state.isLoadingLikedStories = false
		},

		getFollowingSuccess(state, action) {
			const { customTokenIds } = action.payload
			state.followingCustomTokenIds = customTokenIds
			state.isLoadingFollowing = false
		},

		getFollowingFailure(state) {
			state.isLoadingFollowing = false
		},

		getPointsSuccess(state, action) {
			const { xp } = action.payload
			state.xp = xp
			state.isLoadingPoints = false
		},

		getPointsFailure(state) {
			state.isLoadingPoints = false
		},

		setLoggedIn(state, action) {
			state.isLoggedIn = action.payload
		},

	},
})

// Reducer
export default slice.reducer

// Actions
export const { onToggleFollow, deleteUser, setLoggedIn } = slice.actions

// ----------------------------------------------------------------------

/* export function getProfile() {
	return async () => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('/api/user/profile')
			dispatch(slice.actions.getProfileSuccess(response.data.profile))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
} */

// ----------------------------------------------------------------------

export function getPosts() {
	return async () => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('/api/user/posts')
			dispatch(slice.actions.getPostsSuccess(response.data.posts))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

export function getFollowers() {
	return async () => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('/api/user/social/followers')
			dispatch(slice.actions.getFollowersSuccess(response.data.followers))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

export function getFriends() {
	return async () => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('/api/user/social/friends')
			dispatch(slice.actions.getFriendsSuccess(response.data.friends))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

export function getGallery() {
	return async () => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('/api/user/social/gallery')
			dispatch(slice.actions.getGallerySuccess(response.data.gallery))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

export function getUserList() {
	return async () => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('/api/user/manage-users')
			dispatch(slice.actions.getUserListSuccess(response.data.users))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

export function getCards() {
	return async () => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('/api/user/account/cards')
			dispatch(slice.actions.getCardsSuccess(response.data.cards))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

export function getAddressBook() {
	return async () => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('/api/user/account/address-book')
			dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

export function getInvoices() {
	return async () => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('/api/user/account/invoices')
			dispatch(slice.actions.getInvoicesSuccess(response.data.invoices))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

export function getNotifications() {
	return async () => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('/api/user/account/notifications-settings')
			dispatch(slice.actions.getNotificationsSuccess(response.data.notifications))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

export function getUsers() {
	return async () => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('/api/user/all')
			dispatch(slice.actions.getUsersSuccess(response.data.users))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

/* export function getPfps() {
	return async () => {
		dispatch(slice.actions.startLoadingCustomTokens())
		try {
			// console.log('users-fns-getCustomTokenPfps')
			const functions = getFunctions()
			const fn = httpsCallable(functions, 'users-fns-getCustomTokenPfps')
			const requestData = {} as any

			const customTokens:Array<ICustomTokenPfpResponse> = (await fn(requestData))?.data as Array<ICustomTokenPfpResponse>
			// console.log('customTokens=', customTokens)
			dispatch(slice.actions.getPfpsSuccess(customTokens))
		} catch (error) {
			dispatch(slice.actions.hasErrorLoadingCustomTokens(error))
		}
	}
} */

export function getProfile(uid: string) {
	return async () => {
		dispatch(slice.actions.startLoading())
		try {
			const db = getFirestore()

			const docRef = doc(db, 'users', uid)
			const docSnap = await getDoc(docRef)

			if (docSnap.exists()) {
				dispatch(slice.actions.getProfileSuccess(docSnap.data()))
			} else {
				dispatch(slice.actions.hasError(true))
			}
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// --------------------------------------------------------
export interface ImportWalletRequest {
	walletId: string
}

type ImportWalletResponse = {
	status: 'SUCCESS'
} |
{
	status: 'FAILURE'
	reason: 'INCORRECT OWNER' | 'NOT VERIFIED'
}

export function importWallet(walletId: string) {
	return async () => {
		// dispatch(slice.actions.startImportingWallet({ walletId, importStatus: 'RUNNING' }))
		try {
			// console.log('users-fns-importWallet, walletId=', walletId)

			// const db = getFirestore()
			// const docRef = doc(db, 'wallets', walletId)
			// const unsubscribe = onSnapshot(docRef, (doc) => {
			// 	console.log('doc.data()=', doc.data())
			// 	if (doc.data()?.importStatus) {
			// 		dispatch(slice.actions.importWalletStatusUpdate({ walletId, importStatus: doc.data()?.importStatus as string }))
			// 	}
			// })

			const functions = getFunctions()
			const fn = httpsCallable<ImportWalletRequest, ImportWalletResponse>(functions, 'users-fns-importWallet')
			const requestData = { walletId } as any

			await fn(requestData)

			// if (data.status === 'FAILURE') {
			// 	dispatch(slice.actions.hasErrorImportingWallet({ reason: data.reason }))
			// }
		} catch (error) {
			console.error(error)
			// dispatch(slice.actions.importWalletStatusUpdate({ walletId, importStatus: 'FAILURE' }))
		}
	}
}

interface RefreshMetadataProps {
	customTokenId: string
}
export function refreshMetadata({
	customTokenId,
}:RefreshMetadataProps) {
	return async () => {
		// dispatch(slice.actions.startLoadingMetadata(customTokenId))

		try {
			const functions = getFunctions()
			// const db = getFirestore()
			// const docRef = doc(db, 'customizedTokens', customTokenId)
			// const unsubscribe = onSnapshot(docRef, (doc) => {
			// 	// console.log('doc.data()?.aIDescriptionStatus=', doc.data()?.aIDescriptionStatus)
			// 	if (doc.data()?.metadataStatus === 'SUCCESS') {
			// 		dispatch(slice.actions.refreshMetadataSuccess({ customTokenId, customToken: doc.data() as ICustomTokenPfpResponse }))
			// 	}
			// 	if (doc.data()?.metadataStatus === 'FAILURE') {
			// 		dispatch(slice.actions.refreshMetadataFailure({ customTokenId }))
			// 	}
			// })

			const fn = httpsCallable(functions, 'pfps-fns-refreshMetadata')
			const requestData = {
				customTokenId,
			}
			await fn(requestData)
		} catch (error) {
			// dispatch(slice.actions.refreshMetadataFailure({ customTokenId }))
		}
	}
}

/* export function getWallets(uid: string) {
	return async () => {
		dispatch(slice.actions.startLoadingWallets())
		try {
			const db = getFirestore()
			const collectionRef = collection(db, 'wallets')
			const querySnapshot = await getDocs(query(collectionRef, where('uid', '==', uid)))

			const wallets: Array<TWallet> = querySnapshot.docs.map((doc):TWallet => {
				const wallet:TWallet = {
					id: doc.id,
					...doc.data() as Omit<TWallet, 'id'>,
				}
				return wallet
			})

			dispatch(slice.actions.getWalletsSuccess({ wallets }))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
} */

// export function addWallet(uid: string, address: string) {
// 	return async () => {
// 		dispatch(slice.actions.startLoadingWallets())
// 		try {
// 			const db = getFirestore()
// 			const collectionRef = collection(db, 'wallets')
// 			const querySnapshot = await getDocs(query(collectionRef, where('uid', '==', uid), where('address', '==', address)))

// 			if (querySnapshot.size === 0) {
// 				const newWallet:Omit<TWallet, 'id' | 'importStatus'> = {
// 					address,
// 					uid,
// 					chain: 'eth',
// 				}

// 				const docRef = await addDoc(collection(db, 'wallets'), newWallet)

// 				dispatch(slice.actions.addWalletSuccess({ wallet: { id: docRef.id, ...newWallet } }))
// 			}
// 		} catch (error) {
// 			console.log(error)
// 			dispatch(slice.actions.hasError(error))
// 		}
// 	}
// }

// interface IUserStats {
// 	likes: Array<string>
// 	following: Array<string>
// 	shares: Array<string>
// }

// ------------------------------------------------------------
interface GetLikedStoriesProps {
	uid: string
}
export const getLikedStories = ({ uid }:GetLikedStoriesProps) => (dispatch:Dispatch): Unsubscribe | undefined => {
	dispatch(slice.actions.startLoadingLikedStories())
	try {
		const db = getFirestore()
		const collectionRef = collection(db, 'users', uid, 'story_likes')
		const q = query(collectionRef)
		const unsubscribe: Unsubscribe = onSnapshot(q, (querySnapshot) => {
			dispatch(slice.actions.getLikedStoriesSuccess({
				storyIds: querySnapshot.docs.map((doc) => doc.id),
			}))
		})

		return unsubscribe
	} catch (error) {
		dispatch(slice.actions.getLikedStoriesFailure())
	}
	return undefined
}

// ------------------------------------------------------------
interface GetFollowingProps {
	uid: string
}
export const getFollowing = ({ uid }:GetFollowingProps) => (dispatch:Dispatch): Unsubscribe | undefined => {
	dispatch(slice.actions.startLoadingFollowing())
	try {
		const db = getFirestore()
		const collectionRef = collection(db, 'users', uid, 'following')
		const q = query(collectionRef)
		const unsubscribe: Unsubscribe = onSnapshot(q, (querySnapshot) => {
			dispatch(slice.actions.getFollowingSuccess({
				customTokenIds: querySnapshot.docs.map((doc) => doc.id),
			}))
		})

		return unsubscribe
	} catch (error) {
		dispatch(slice.actions.getFollowingFailure())
	}
	return undefined
}

// ----------------------------------------------------------------------

interface UpdateFollowingProps {
	customTokenId: string
	changeBy: number
}

export function updateFollowing(props:UpdateFollowingProps) {
	return async () => {
		try {
			const functions = getFunctions()
			const fn = httpsCallable(functions, 'users-fns-updateFollowing')
			await fn(props)
		} catch (error) {
			console.error(error)
		}
	}
}

// ----------------------------------------------------------------------
interface GetPointsProps {
	uid: string
}
export const getPoints = ({ uid }:GetPointsProps) => (dispatch:Dispatch): Unsubscribe | undefined => {
	dispatch(slice.actions.startLoadingLikedStories())
	try {
		const db = getFirestore()

		const docRef = doc(db, 'users', uid, 'extended_user_collection', 'extended_user_data')

		const unsubscribe: Unsubscribe = onSnapshot(docRef, (doc) => {
			if (doc.exists()) {
				const xp:number | undefined = doc.data()?.xp
				dispatch(slice.actions.getPointsSuccess({
					xp: xp ?? 0,
				}))
			}
		}, (err) => {
			// logger.error(err)
		})

		return unsubscribe
	} catch (error) {
		logger.error(error)
		dispatch(slice.actions.getPointsFailure())
	}
	return undefined
}

// ------------------------------------------------------------

interface UpdateAvatarProps {
	uid: string
	avatarUrl: string
}

export function updateAvatar({ uid, avatarUrl }: UpdateAvatarProps) {
	return async () => {
		logger.log({ uid, avatarUrl })
		const db = getFirestore()
		const docRef = doc(db, 'users', uid)
		await setDoc(docRef, { avatarUrl }, { merge: true })
	}
}
