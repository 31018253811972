import { createSlice } from '@reduxjs/toolkit'
// utils
import { getFunctions, httpsCallable } from 'firebase/functions'
import _ from 'lodash'
import axios from '../../utils/axios'
import { CardStackState, Card } from '../../@types/cardStackv2'
//

import { store } from '../store'

// ----------------------------------------------------------------------

const initialState: CardStackState = {
	isLoading: false,
	error: null,
	cards: [],
	session: {
		id: _.uniqueId(),
		activeStep: 0,
		cards: [],
	},
}

const slice = createSlice({
	name: 'cardStack',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		// GET PRODUCTS
		getCardsSuccess(state, action) {
			state.isLoading = false
			state.cards = action.payload
		},

		newSession(state) {
			console.log('Dispatch: newSession')
			state.session.activeStep = 0
			// state.session.cards = []
			state.session.id = _.uniqueId()
		},

		onLeft(state) {
			console.log('Dispatch: onLeft')
			state.session.activeStep += 1
		},

		onRight(state) {
			console.log('Dispatch: onRight')
			state.session.activeStep += 1
		},

	},
})

// Reducer
export default slice.reducer

// Actions
export const {
	newSession,
	onLeft,
	onRight,
} = slice.actions

// ----------------------------------------------------------------------

export function getCards() {
	console.log('Dispatch: getCards')
	return async () => {
		const { dispatch } = store
		dispatch(slice.actions.startLoading())
		try {
			const functions = getFunctions()
			const fn = httpsCallable(functions, 'cardstack-fnsDemo-getAllCardsForDemo2')
			const requestData = {} as any

			const cards:Array<Card> = (await fn(requestData))?.data as Array<Card>

			dispatch(slice.actions.getCardsSuccess(cards))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getCards2() {
	console.log('Dispatch: getCards')
	return async () => {
		const { dispatch } = store
		dispatch(slice.actions.startLoading())
		try {
			const functions = getFunctions()
			const fn = httpsCallable(functions, 'cardstack-fnsDemo-nextCardBatch')
			const requestData = {} as any

			const cards:Array<Card> = (await fn(requestData))?.data as Array<Card>

			console.log('cards=', cards)

			dispatch(slice.actions.getCardsSuccess(cards))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
