import { createSlice, Dispatch } from '@reduxjs/toolkit'
import {
	doc, getDoc, getFirestore, onSnapshot, query,
	increment, serverTimestamp, setDoc, Timestamp, collection, getDocs, where, addDoc, Unsubscribe, deleteDoc, FieldValue, collectionGroup,
} from 'firebase/firestore'
import { compact } from 'lodash'
import { logger } from 'src/services/logger'
import { TFollowersState } from 'src/types/Followers'
// ----------------------------------------------------------------------

const initialState: TFollowersState = {
	isLoaded: false,
	isLoading: false,
	error: false,
	uids: [],
	ignoredUids: [],
}

const slice = createSlice({
	name: 'followers',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoaded = false
			state.isLoading = true
			state.error = false
		},

		// HAS ERROR
		getFailure(state) {
			state.isLoaded = false
			state.isLoading = false
			state.error = true
		},

		// GET PROMOTIONS
		getSuccess(state, action) {
			const { uids } = action.payload
			state.isLoaded = true
			state.isLoading = false
			state.uids = uids
			state.error = false
		},

		getIgnoredSuccess(state, action) {
			const { uids } = action.payload
			state.ignoredUids = uids
		},

	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface GetFollowersProps {
	uid: string
}
export const getFollowers = ({ uid }:GetFollowersProps) => (dispatch:Dispatch): Unsubscribe | undefined => {
	dispatch(slice.actions.startLoading())
	try {
		logger.log('getFollowers')
		const db = getFirestore()
		const collectionGroupRef = collectionGroup(db, 'following')
		// const q = query(collectionGroupRef, where('uid', '==', uid), where('ignore', '!=', true))
		const q = query(collectionGroupRef, where('uid', '==', uid))
		const unsubscribe: Unsubscribe = onSnapshot(q, (querySnapshot) => {
			// logger.log(querySnapshot.size)
			const uids: Array<string> = compact(querySnapshot.docs.map((doc) => doc.data()?.followerUid))
			dispatch(slice.actions.getSuccess({
				uids,
			}))
		})

		return unsubscribe
	} catch (error) {
		dispatch(slice.actions.getFailure())
	}
	return undefined
}

// ----------------------------------------------------------------------

interface GetIgnoredFollowersProps {
	uid: string
}
export const getIgnoredFollowers = ({ uid }:GetIgnoredFollowersProps) => (dispatch:Dispatch): Unsubscribe | undefined => {
	dispatch(slice.actions.startLoading())
	try {
		logger.log('getIgnoredFollowers')
		const db = getFirestore()
		const collectionGroupRef = collectionGroup(db, 'following')
		const q = query(collectionGroupRef, where('uid', '==', uid), where('ignore', '==', true))
		const unsubscribe: Unsubscribe = onSnapshot(q, (querySnapshot) => {
			// logger.log(querySnapshot.size)
			const uids: Array<string> = compact(querySnapshot.docs.map((doc) => doc.data()?.followerUid))
			dispatch(slice.actions.getIgnoredSuccess({
				uids,
			}))
		})

		return unsubscribe
	} catch (error) {
		dispatch(slice.actions.getFailure())
	}
	return undefined
}

// ----------------------------------------------------------------------

interface IgnoreFriendRequestProps {
	myUid: string
	uidOfFollower: string
}
export function ignoreFriendRequest(props: IgnoreFriendRequestProps) {
	return async (dispatch: Dispatch) => {
		const { uidOfFollower, myUid } = props
		// dispatch(slice.actions.removeFollowing({ uid: uidToUnFollow }))
		const db = getFirestore()
		const docRef = doc(db, 'users', uidOfFollower, 'following', myUid)
		await setDoc(docRef, {
			ignore: true,
			updatedAt: serverTimestamp(),
		}, { merge: true })
	}
}

// ----------------------------------------------------------------------
