/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense, ElementType } from 'react'
import {
	Navigate, useRoutes, useLocation, createBrowserRouter, RouterProvider,
} from 'react-router-dom'
import { lazy } from 'src/utils/componentLoader'
import RNAuthGuard from 'src/guards/RNAuthGuard'
import GuestGuard from '../guards/GuestGuard'
import LoadingScreen from '../components/LoadingScreen'
import MainLayout from '../layouts/main'
import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
import useAuth from '../hooks/useAuth'

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { pathname } = useLocation()

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { isAuthenticated, user } = useAuth()
	const isAnonymous: boolean = isAuthenticated && !!user && user.isAnonymous

	const isDashboard = pathname.includes('/dashboard') && isAuthenticated && !isAnonymous

	return (
		<Suspense fallback={<LoadingScreen showProgressBar isDashboard={isDashboard} />}>
			<Component {...props} />
		</Suspense>
	)
}

// const CompanyAboutPage = Loadable(lazy(() => import('../pages/about/AboutGeneral')))

// const NftsAboutPage = Loadable(lazy(() => import('../pages/about/nfts/User')))
// const NftsCreatorsAboutPage = Loadable(lazy(() => import('../pages/about/nfts/Creator')))

// const CharactersAboutPage = Loadable(lazy(() => import('../pages/about/characters/User')))
// const CharactersCreatorsAboutPage = Loadable(lazy(() => import('../pages/about/characters/Creator')))

// const CollectionsAboutPage = Loadable(lazy(() => import('../pages/CollectionsAboutPage')))

const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')))
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')))
// const PushNotificationTester = Loadable(lazy(() => import('../pages/PushNotifs')))

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/LoginPage')))
const WaitForNativeLogin = Loadable(lazy(() => import('../pages/auth/WaitForNativeLogin')))
const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')))

const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')))
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')))

const WWWGift = Loadable(lazy(() => import('../pages/www/WWWGift')))
const WWWGiftCat = Loadable(lazy(() => import('../pages/www/WWWGiftcat')))
const WWWStoryFoothill = Loadable(lazy(() => import('../pages/www/WWWStoryFoothill')))
const WWWKidBot = Loadable(lazy(() => import('../pages/www/WWWKidBot')))
const WWWKidBotWithDialogue = Loadable(lazy(() => import('../pages/www/WWWKidBotDialogue1')))

// Defaults
const Page500 = Loadable(lazy(() => import('../pages/Page500')))
const Page403 = Loadable(lazy(() => import('../pages/Page403')))
const Page404 = Loadable(lazy(() => import('../pages/Page404')))

export const router = createBrowserRouter([
	// {
	// 	path: 'whitelist',
	// 	element: <Navigate to="/" replace />,
	// },
	// {
	// 	path: 'beta',
	// 	element: <Navigate to="/" replace />,
	// },
	// {
	// 	path: 'collections',
	// 	element: <MainLayout />,
	// 	children: [
	// 		{ element: <CollectionsSignup />, index: true },
	// 	],
	// },
	// { path: 'beta/:wallet', element: <BetaSignup /> },
	{
		path: 'auth',
		children: [
			// {
			// 	path: 'connect',
			// 	element: (
			// 		<GuestGuard>
			// 			<ConnectWallet />
			// 		</GuestGuard>
			// 	),
			// },
			 {
				path: 'waitfornativelogin',
				element: (
					<GuestGuard>
						<WaitForNativeLogin />
					</GuestGuard>
				),
			},
			{
				path: 'login',
				element: (
					<GuestGuard>
						<Login />
					</GuestGuard>
				),
			},
			{
				path: 'register',
				element: (
					<GuestGuard>
						<RegisterPage />
					</GuestGuard>
				),
			},
			{
				path: 'reset-password',
				element: (
					<GuestGuard>
						<ResetPassword />
					</GuestGuard>
				),
			},
			{
				path: 'new-password',
				element: (
					<GuestGuard>
						<NewPassword />
					</GuestGuard>
				),
			},
		],
	},
	// {
	// 	path: 'auth',
	// 	children: [
	// 		{
	// 			path: 'connect', element: <Navigate to="/coming-soon" replace />, index: true,
	// 		},
	// 	],
	// },
	// Demo Routes
	/* 	{
			path: 'demos',
			element: (
				<AuthGuard>
					<MainLayout />
				</AuthGuard>
			),
			children: [
				// { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
				// { path: PATH_DEMOS.root, element: <DemosOverview /> },
				// { path: PATH_DEMOS.general.chats, element: <Chats /> },
				// { path: PATH_DEMOS.general.spawns, element: <Spawns /> },
				// { path: PATH_DEMOS.spawn.root, element: <Spawn /> },
				// { path: 'booking', element: <GeneralBooking /> },
			],
		}, */

		    // Dashboard Routes

	// {
	// 	path: 'browse',
	// 	element: (
	// 		<AuthGuard>
	// 			<MainLayout>
	// 				<MyNFTsBrowse />
	// 			</MainLayout>
	// 		</AuthGuard>
	// 	),
	// },
	/* 	{
		path: 'nfts',
		element: (
			<AuthGuard>
				<BuildNoNavLayout />
			</AuthGuard>
		),
		children: [
			{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
			// { path: 'browse', element: <MyNFTsBrowse />, index: true },
			// { path: ':id/general', element: <BuildNFTGeneral /> },
			// { path: 'configure', element: <BuildNFTGeneral /> },
			// { path: 'personalize', element: <BuildNFTAboutMe /> },
			// { path: 'mods', element: <BuildNFTGeneral3 /> },
			// { path: 'stories/:customTokenId', element: <StoriesListPage /> },
			{ path: 'kanban', element: <KanbanDemo /> },
			// { path: ':id/configure', element: <BuildNFTGeneral /> },
		],
	}, */

	/* {
		path: 'user',
		element: (
			<AuthGuard>
				<BuildNoNavLayout />
			</AuthGuard>
		),
		children: [
			{ path: 'account', element: <UserAccount /> },
		],
	}, */
	/* {
		path: 'nfts/kanban',
		element: (
			<BuildNFTGeneral2 />
		),

	}, */
	// {
	// 	path: 'nfts/browse',
	// 	element: (
	// 		<AuthGuard>
	// 			<BuildNoNavLayout />
	// 		</AuthGuard>
	// 	),
	// 	children: [
	// 		{ element: <MyNFTsBrowse />, index: true },
	// 	],
	// },

	// Main Routes
	{
		path: '*',
		element: <LogoOnlyLayout />,
		children: [
			{ path: 'coming-soon', element: <ComingSoon /> },
			{ path: 'maintenance', element: <Maintenance /> },
			// { path: 'pricing', element: <Pricing /> },
			// { path: 'payment', element: <Payment /> },
			// { path: '500', element: <Page500 /> },
			  { path: '500', element: <Page500 /> },
			{ path: '404', element: <Page404 /> },
			{ path: '403', element: <Page403 /> },
			// { path: '403', element: <Page403 /> },
			{ path: '*', element: <Navigate to="/404" replace /> },
		],
	},
	/* 	{
		path: '/',
		element: <MainLayout />,
		children: [
			{ path: 'about', element: <CompanyAboutPage />, index: true },
			{
				path: 'nfts',
				children: [
					{	element: <NftsAboutPage />, index: true },
					{ path: 'creators', element: <NftsCreatorsAboutPage />, index: true },
				],
			},
			{
				path: 'characters',
				children: [
					{	element: <CharactersAboutPage />, index: true },
					{ path: 'creators', element: <CharactersCreatorsAboutPage />, index: true },
				],
			},
			{
				path: 'collections',
				children: [
					{	element: <CollectionsAboutPage />, index: true },
					{ path: 'register', element: <CollectionsSignup />, index: true },
				],
			},
			{ path: 'team', element: <Team />, index: true },
		],
	}, */
	// {
	// 	path: '/:id',
	// 	element: <AIDemoOutsideLayout />,
	// 	children: [
	// 		{ element: <AiDemoOutside />, index: true },
	// 		// { path: ':id/render', element: <AiDemoInside /> },
	// 	],
	// },
	// {
	// 	path: '/',
	// 	element: <AIDemoOutsideLayout />,
	// 	children: [
	// 		// { element: <WebAppOutside />, index: true },
	// 		{ element: <PushNotificationTester />, index: true },
	// 		// { path: ':id/render', element: <AiDemoInside /> },
	// 	],
	// },
	// {
	// 	path: '/:storyId',
	// 	element: <AIDemoOutsideLayout />,
	// 	children: [
	// 		{ element: <WebAppOutside />, index: true },
	// 		// { path: ':id/render', element: <AiDemoInside /> },
	// 	],
	// },
	{
		path: '/gift/beer',
		element: (
			<WWWGift />
		),
	},
	{
		path: '/gift/cat',
		element: (
			<WWWGiftCat />
		),
	},
	{
		path: '/story/:storyName/:playerName',
		element: (
			<WWWStoryFoothill />
		),
	},
	{
		path: '/kidbot/:playerName',
		element: (
			<WWWKidBot />
		),
	},
	{
		path: '/kidbot/:playerName/:voice',
		element: (
			<WWWKidBot />
		),
	},
	{
		path: '/kidbot/dialogue/:playerName',
		element: (
			<WWWKidBotWithDialogue />
		),
	},
	// {
	// 	path: '/thread/:threadId',
	// 	element: (
	// 		<RNAuthGuard>
	// 			<WebAppStandAlone />
	// 		</RNAuthGuard>
	// 	),
	// },

	// {
	// 	path: '/tinder',
	// 	element: (
	// 		<TinderSwipeTest />
	// 	),
	// },
	// {
	// 	path: 'webapp/inside',
	// 	element: (
	// 		<AuthGuard>
	// 			<WebAppInside />
	// 		</AuthGuard>
	// 	),
	// },
	// {
	// 	path: 'stories/inside/:storyId',
	// 	element: <WebAppInside />,
	// 	// element: <StoriesInsidePreview />,
	// },

	// {
	// 	path: 'team',
	// 	element: <MainLayout />,
	// 	children: [
	// 		{ element: <Team />, index: true },
	// 	],
	// },
	// {
	// 	path: 'import',
	// 	element: <LowChromeLayout />,
	// 	children: [
	// 		{ element: <Try />, index: true },
	// 	],
	// },
	// {
	// 	path: 'try2',
	// 	element: <LowChromeLayout />,
	// 	children: [
	// 		{ element: <Try2 />, index: true },
	// 	],
	// },
	// {
	// 	path: 'try',
	// 	element: <MainLayout />,
	// 	children: [
	// 		{ element: <WhitelistSignup />, index: true },
	// 	],
	// },

	// {
	// 	path: '/',
	// 	element: <NewsLayout />,
	// 	children: [
	// 		{ element: <FeedMain />, index: true },
	// 		{ path: '/:handle', element: <FeedMain /> },
	// 		{ path: '/:handle/try', element: <Interact /> },
	// 	],
	// },
	// {
	// 	path: 'ai',
	// 	element: <AIDemoOutsideLayout />,
	// 	children: [
	// 		{ path: ':id', element: <AiDemoOutside /> },
	// 		// { path: ':id/render', element: <AiDemoInside /> },
	// 	],
	// },
	// {
	// 	path: 'ai-inside/:id',
	// 	element: <AiDemoInside />,
	// },
	/* {
		path: 'ai-inside',
		element: <AIDemoInsideLayout />,
		children: [
			{ path: ':id', element: <AiDemoInside /> },
		],
	}, */

	// { path: '/', element: <Navigate to="/maintenance" replace /> },
	{ path: '*', element: <Navigate to="/404" replace /> },
])
