import { isNil } from 'lodash'
import React, { ReactNode } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
// import { navigate } from 'gatsby'
// hooks
import useAuth from '../hooks/useAuth'
// routes
import { PATH_DASHBOARD, PATH_DEMOS, PATH_PAGE } from '../routes/paths'

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode
}

const LOCK_SITE = process.env.REACT_APP_LOCK_SITE as string

export default function GuestGuard({ children }: GuestGuardProps) {
	const { isAuthenticated, user } = useAuth()
	const isAnonymous: boolean = isAuthenticated && !!user && user.isAnonymous
	  const location = useLocation()
	// const navigate = useNavigate()

	if (LOCK_SITE === 'true') {
		return <Navigate to="/coming-soon" replace />
	}

	const from = (location.state as any)?.from?.pathname || PATH_PAGE.webapp
	// if (isAuthenticated && !isNil(walletAccount)) {
	// 	return <Navigate to={from} replace />
	// }
	if (isAuthenticated && !isAnonymous) {
		return <Navigate to={from} replace />
	}

	return <>{children}</>
}

/* export default function GuestGuard({ children }: GuestGuardProps) {
	const { isAuthenticated } = useAuth()

	if (isAuthenticated) {
		navigate(PATH_DASHBOARD.general.app, { replace: true })
		return null
		// return <Redirect to={PATH_DASHBOARD.root} />
	}

	return <>{children}</>
} */

/* const GuestGuard:React.FC<{component:any}> = ({ component: Component, ...rest }) => {
	const { isAuthenticated } = useAuth()
	if (isAuthenticated) {
		// navigate(PATH_DASHBOARD.root, { replace: true })
		navigate(PATH_DEMOS.root, { replace: true })
		return null
		// redirectTo(PATH_DASHBOARD.root)
		// return null
		// return <Redirect to={PATH_DASHBOARD.root} />
	}

	return <Component {...rest} />
}

export default GuestGuard */
