// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ar = {
	demo: {
		title: 'Arabic',
		introduction: 'لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة. لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري. تم نشره في الستينيات من القرن الماضي بإصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.',
	},
	docs: {
		hi: 'أهلا',
		description: 'تحتاج مساعدة؟ \n الرجاء مراجعة مستنداتنا',
		documentation: 'توثيق',
	},
	app: 'تطبيق',
	user: 'المستعمل',
	list: 'قائمة',
	edit: 'تعديل',
	shop: 'متجر',
	blog: 'مقالات',
	post: 'بريد',
	mail: 'بريد',
	chat: 'دردشة',
	cards: 'البطاقات',
	posts: 'المشاركات',
	create: 'يزيد',
	kanban: 'كانبان',
	general: 'جنرال لواء',
	banking: 'الخدمات المصرفية',
	booking: 'الحجز',
	profile: 'الملف الشخصي',
	account: 'الحساب',
	product: 'المنتوج',
	invoice: 'فاتورة',
	details: 'تفاصيل',
	checkout: 'الدفع',
	calendar: 'التقويم',
	analytics: 'التحليلات',
	ecommerce: 'التجارة الإلكترونية',
	management: 'إدارة',
	menu_level_1: 'مستوى القائمة 1',
	menu_level_2: 'مستوى القائمة 2',
	menu_level_3: 'مستوى القائمة 3',
	item_disabled: 'العنصر معطل',
	item_label: 'تسمية العنصر',
	item_caption: 'عنوان العنصر',
	description: 'وصف',
	other_cases: 'حالات اخرى',
	item_by_roles: 'عنصر حسب الأدوار',
	only_admin_can_see_this_item: 'يمكن للمسؤول فقط رؤية هذا العنصر',
}

export default ar
