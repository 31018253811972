import { IteratedDataSnapshot } from 'firebase/database'
import { DocumentData, Timestamp } from 'firebase/firestore'
import { logger } from 'src/services/logger'
import { IFeedItemComment } from 'src/types/Feed'
import { IThreadActivity } from 'src/types/ThreadActivities'
import { IGiftThreadMessage, IThreadMessage } from 'src/types/ThreadMessages'
import {
	IGiftThread,
	IGiftThreadFromDb,
	IThread, IThreadFromDb,
} from 'src/types/Threads'

/* export const firestoreToIThread = (doc: DocumentData):IThread => {
	const data = doc.data() as Omit<IThreadFromDb, 'id'>

	const dataOut:IThread = {
		id: doc.id,
		...data,
		createdAtDate: data.createdAt.toDate(),
		updatedAtDate: data.updatedAt.toDate(),
	}
	return dataOut
} */

// export const uidsKey = (uids: Array<string>):string => uids.join('-')

export const getPartnerUid = (uids: Array<string>, myUid:string): string | undefined => uids.find((uid) => uid !== myUid)

export const firestoreToIThread = (doc: DocumentData):IThread => {
	const data = doc.data() as Omit<IThreadFromDb, 'id'>

	// const partnerUid: string | undefined = data.uids.find((uid) => uid !== myUid)

	// if (!partnerUid) {
	// 	throw Error('No partner found in thread')
	// }

	const dataOut:IThread = {
		id: doc.id,
		...data,
		// partnerUid,
		createdAtDate: data.createdAt.toDate(),
		updatedAtDate: data.updatedAt.toDate(),
	}
	return dataOut
}

export const firestoreToIThreadMessage = (doc: DocumentData):IThreadMessage => {
	const data = doc.data() as Omit<IThreadMessage, 'id'>

	const dataOut:IThreadMessage = {
		id: doc.id,
		...data,
		createdAtDate: data.createdAt.toDate(),
	}

	return dataOut
}

export const firestoreToIThreadActivity = (doc: DocumentData):IThreadActivity => {
	const data = doc.data() as Omit<IThreadActivity, 'id'>

	const dataOut:IThreadActivity = {
		id: doc.id,
		...data,
		createdAtDate: data.createdAt.toDate(),
	}

	return dataOut
}

// export const rtdbToIThreadMessage = (childSnapshot: IteratedDataSnapshot):IThreadMessage => {
// 	const childKey = childSnapshot.key
// 	const message: Omit<IThreadMessage, 'id'> = {
// 		...(childSnapshot.val() as Omit<IThreadMessage, 'id'>),
// 		createdAtDate: new Date(childSnapshot.val()?.createdAt),
// 	}
// 	return { id: childKey, ...message }
// }

export const firestoreToIFeedItemComment = (doc: DocumentData):IFeedItemComment => {
	const data = doc.data() as Omit<IFeedItemComment, 'id'>

	const dataOut:IFeedItemComment = {
		id: doc.id,
		...data,
		createdAtDate: data.createdAt.toDate(),
	}

	return dataOut
}

export const firestoreFeedCommentToIFeedItemComment = (data: any):IFeedItemComment => {
	// logger.warn({ data })
	let ts
	// NOTE: shit show, cause in emulator, the json represents as 'seconds' but on server, json represents as '_seconds'

	if (data?.createdAt?.seconds) {
		ts = new Timestamp(data.createdAt.seconds, data.createdAt.nanoseconds)
	} else if (data?.createdAt?._seconds) {
		ts = new Timestamp(data.createdAt._seconds, data.createdAt._nanoseconds)
	}

	const comment: Omit<IFeedItemComment, 'id'> = {
		...(data as Omit<IFeedItemComment, 'id'>),
		createdAtDate: (ts as Timestamp).toDate(),
	}
	// logger.warn({ message })
	return { id: data.id, ...comment }
}
// export const firestoreToIThreadSummary = (doc: DocumentData):IThreadSummary => {
// 	const data = doc.data() as Omit<IThreadSummaryFromDb, 'id'>

// 	const fullSummary = `${data?.previousSummary ? `${data.previousSummary} ` : ''}${data.appendedSummary}`

// 	const dataOut:IThreadSummary = {
// 		id: doc.id,
// 		...data,
// 		fullSummary,
// 		createdAtDate: data.createdAt.toDate(),
// 		updatedAtDate: data.updatedAt.toDate(),
// 	}
// 	return dataOut
// }

export const firestoreToIGiftThreadMessage = (doc: DocumentData):IGiftThreadMessage => {
	const data = doc.data() as Omit<IGiftThreadMessage, 'id'>

	const dataOut:IGiftThreadMessage = {
		id: doc.id,
		...data,
		createdAtDate: data.createdAt.toDate(),
	}

	return dataOut
}

export const firestoreToIGiftThread = (doc: DocumentData):IGiftThread => {
	const data = doc.data() as Omit<IGiftThreadFromDb, 'id'>

	const dataOut:IGiftThread = {
		id: doc.id,
		...data,
		createdAtDate: data.createdAt.toDate(),
		updatedAtDate: data.updatedAt.toDate(),
	}
	return dataOut
}
