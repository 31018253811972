import { createSlice } from '@reduxjs/toolkit'
import { avatarBackgroundColors, defaultAvatarBackgroundColor } from 'src/configs/Avatars'
import { TAvatarState } from 'src/types/Avatars'

// ----------------------------------------------------------------------

const initialState: TAvatarState = {
	backgroundColors: avatarBackgroundColors,
	backgroundColor: defaultAvatarBackgroundColor,
	avatarImageName: null,
}

const slice = createSlice({
	name: 'avatars',
	initialState,
	reducers: {},
})

// Reducer
export default slice.reducer
