import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { IPromotion } from 'src/types/IPromotion'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { ICustomTokenSpaceResponse } from 'src/@types/space'
import { TPeopleState } from 'src/types/People'
import {
	doc, getDoc, getFirestore, onSnapshot, query,
	increment, serverTimestamp, setDoc, Timestamp, collection, getDocs, where, addDoc, Unsubscribe, orderBy, limit,
} from 'firebase/firestore'
import { IPerson } from 'src/@types/people'
import keyBy from 'lodash/keyBy'
import { differenceInMinutes } from 'date-fns'
import { logger } from 'src/services/logger'
import { sample } from 'lodash'
import { coerceShortDisplayName } from 'src/utils/users'

// ----------------------------------------------------------------------

const initialState: TPeopleState = {
	isLoading: false,
	error: false,
	people: [],
	byUid: {},
	byHandleLowerCase: {},
}

const slice = createSlice({
	name: 'people',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.error = false
		},

		// HAS ERROR
		getPeopleFailure(state) {
			state.isLoading = false
			state.error = true
		},

		// GET PROMOTIONS
		getPeopleSuccess(state, action) {
			const { people } = action.payload
			state.isLoading = false
			state.people = people
			state.byUid = keyBy(people, 'uid')
			state.byHandleLowerCase = keyBy(people, 'handleLowerCase')
			state.error = false
		},
	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export const getPeople = () => (dispatch:Dispatch): Unsubscribe | undefined => {
	dispatch(slice.actions.startLoading())
	try {
		logger.log('getPeople')
		const db = getFirestore()
		const collectionRef = collection(db, 'user_public_profiles')
		// const q = query(collectionRef, orderBy('lastSeen', 'desc'), limit(200))
		const q = query(collectionRef, limit(200))
		const unsubscribe: Unsubscribe = onSnapshot(q, (querySnapshot) => {
			// const filtered = querySnapshot.docs.filter((doc)=>{
			// 	const person:IPerson = doc.data()
			// 	if (!!person.displayName && !!person.handle)
			// })

			const people = querySnapshot.docs.map((doc) => {
				let { lastSeen }:{lastSeen: Timestamp | undefined} = doc.data() as any
				lastSeen = lastSeen ?? new Timestamp(0, 0)
				// const lastSeen
				// logger.log('-----')
				// logger.log(lastSeen)
				// const dtLastSeen:Date = new Date(lastSeen.toDate().toLocaleTimeString('en-US', { timeZone: 'America/New_York' }))
				const dtLastSeen:Date = lastSeen.toDate()
				const dtNow: Date = new Date()
				// logger.log(dtLastSeen)
				// logger.log(dtNow)

				const person:IPerson = {
					uid: doc.id,
					...doc.data() as Omit<IPerson, 'uid' | 'lastSeen'>,
					// displayName: coerceShortDisplayName(doc.data() as IPerson),
					handle: doc.data().handle,
					lastSeen: dtLastSeen,
					online: differenceInMinutes(dtNow, dtLastSeen) <= 10,
					doing: sample(['playing with a', 'using a', 'eating', 'talking to', 'looking at', 'thinking about', 'watching the', 'flirting with']),
				}

				return person
			})

			dispatch(slice.actions.getPeopleSuccess({
				people,
			}))
		})

		return unsubscribe
	} catch (error) {
		dispatch(slice.actions.getPeopleFailure())
	}
	return undefined
}
