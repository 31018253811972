// theme
import palette from '../theme/palette'
// @type
import { ThemeColorPresets } from '../components/settings/type'

// ----------------------------------------------------------------------

export const colorPresets = [
	// DEFAULT
/*
	{
		name: 'default',
		// ...palette.light.primary,
		// These are the PURPLE colors from below
		lighter: '#FEE6FF',
		light: '#E2A5FF',
		main: '#A73FFF',
		// main: '#E2A5FF',
		dark: '#882CD7',
		darker: '#511782',
		contrastText: '#fff',
	},
	*/
	// {
	// 	name: 'default',
	// 	// ...palette.light.primary,
	// 	// These are the PURPLE colors from below
	// 	lighter: '#FCD6DB',
	// 	light: '#FCAECE',
	// 	main: '#FA53AD',
	// 	// main: '#E2A5FF',
	// 	dark: '#D54192',
	// 	darker: '#892C5E',
	// 	contrastText: '#fff',
	// },
	// {
	// 	name: 'airbnb',
	// 	lighter: '#FFE0D7',
	// 	light: '#FF8C85',
	// 	main: '#FF5A60',
	// 	// main: '#E2A5FF',
	// 	dark: '#C24449',
	// 	darker: '#8C3235',
	// 	contrastText: '#fff',
	// },
	{
		name: 'airbnb',
		lighter: '#FFE5EA',
		light: '#FF99AB',
		main: '#FF385C',
		// main: '#E2A5FF',
		dark: '#C20A2B',
		darker: '#8A0F25',
		contrastText: '#fff',
	},
	/* 	// PURPLE
	{
		name: 'purple',
		lighter: '#EBD6FD',
		light: '#B985F4',
		main: '#7635dc',
		dark: '#431A9E',
		darker: '#200A69',
		contrastText: '#fff',
	},
	// CYAN
	{
		name: 'cyan',
		lighter: '#D1FFFC',
		light: '#76F2FF',
		main: '#1CCAFF',
		dark: '#0E77B7',
		darker: '#053D7A',
		contrastText: palette.light.grey[800],
	},
	// BLUE
	{
		name: 'blue',
		lighter: '#D1E9FC',
		light: '#76B0F1',
		main: '#2065D1',
		dark: '#103996',
		darker: '#061B64',
		contrastText: '#fff',
	},
	// ORANGE
	{
		name: 'orange',
		lighter: '#FEF4D4',
		light: '#FED680',
		main: '#fda92d',
		dark: '#B66816',
		darker: '#793908',
		contrastText: palette.light.grey[800],
	},
	// RED
	{
		name: 'red',
		lighter: '#FFE3D5',
		light: '#FFC1AC',
		main: '#FF3030',
		dark: '#B71833',
		darker: '#7A0930',
		contrastText: '#fff',
	}, */
]

// export const defaultPreset = colorPresets[0]
export const airbnbPreset = colorPresets[0]
// export const purplePreset = colorPresets[1]
// export const cyanPreset = colorPresets[2]
// export const bluePreset = colorPresets[3]
// export const orangePreset = colorPresets[4]
// export const redPreset = colorPresets[5]

export default function getColorPresets(presetsKey: ThemeColorPresets) {
	return {
		/* purple: purplePreset,
		cyan: cyanPreset,
		blue: bluePreset,
		orange: orangePreset,
		red: redPreset, */
		// default: defaultPreset,
		airbnb: airbnbPreset,
	}[presetsKey]
}
