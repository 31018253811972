import omit from 'lodash/omit'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'
import { logger } from 'src/services/logger'
import { createSlice, Dispatch } from '@reduxjs/toolkit'
// utils
import {
	addDoc, arrayRemove, collection, doc, getDoc, getDocs, getFirestore, increment, query, setDoc, Timestamp, updateDoc, where,
} from 'firebase/firestore'
import {
	IModCard, IModPrompts, IModsBoard, IModsColumn, IModsState2,
} from 'src/@types/mods'
import { getFunctions, httpsCallable } from 'firebase/functions'

// @types
import { IActions, IEffects } from 'src/@types/effect'
import { IKanbanState, IKanbanCard, IKanbanColumn } from '../../@types/kanban'

// ----------------------------------------------------------------------

const initialState: IEffects = {
	actionsIsLoading: false,
	actions: [],
	actionsById: {},
}

const slice = createSlice({
	name: 'effects',
	initialState,
	reducers: {

		// START LOADING
		actionsStartLoading(state) {
			state.actionsIsLoading = true
			state.actions = []
		},

		// GET MOD PROMPTS SUCCESS
		getActionsSuccess(state, action) {
			state.actionsIsLoading = false
			const { actions } = action.payload
			state.actions = actions
			state.actionsById = keyBy(actions, 'id')
			// logger.log(state.actions)
		},

	},
})

// Reducer
export default slice.reducer

export const { actions } = slice

// ----------------------------------------------------------------------

interface GetActionsResponse {
	actions: Array<IActions>
}

export function getActions() {
	return async (dispatch: Dispatch) => {
		logger.log('getActions')
		dispatch(slice.actions.actionsStartLoading())
		const functions = getFunctions()
		const { data } = await httpsCallable<{}, GetActionsResponse>(functions, 'customTokens-fns-getActions')({})
		dispatch(slice.actions.getActionsSuccess({ actions: data.actions }))
	}
}

// ----------------------------------------------------------------------

// interface GetInventoryResponse {
// 	actions: Array<IActions>
// }

// export function getInventoryActions() {
// 	return async (dispatch: Dispatch) => {
// 		logger.log('getActions')
// 		dispatch(slice.actions.actionsStartLoading())
// 		const functions = getFunctions()
// 		const { data } = await httpsCallable<{}, GetInventoryResponse>(functions, 'customTokens-fns-getInventoryActions')({})
// 		dispatch(slice.actions.getActionsSuccess({ actions: data.actions }))
// 	}
// }

// ----------------------------------------------------------------------
