import { Howl } from 'howler'
import React, {
	ReactNode, createContext, useState, useEffect, useMemo, useCallback, ContextType,
} from 'react'
import {
	allPreloadSounds, allStreamingSounds, ISoundEffect, TSound, TStreamingSoundName,
} from 'src/types/ISoundEffect'
import {
	keyBy, map,
} from 'lodash'

//  https://github.com/zolomohan/text-to-speech/blob/master/textToSpeech.js
interface ComponentProps {
	children: any
}

type SoundContextProps = {
	// streamMusic: (soundName: TStreamingSoundName, volume?:number) => void
	// stopCurrentBkgMusic: () => void
	// mute: (muteCmd: boolean)=> void
	preloadAllSounds: () => void
	// loaded: boolean
	speakSentence: (text:string, volume?:number, rate?:number, pitch?: number) => void
	sounds: Record<TSound, ISoundEffect>
	play: (soundName: TSound, volume?: number) => void
}

const initialState: Partial<SoundContextProps> = {
	// streamMusic: (soundName: TStreamingSoundName, volume?:number) => {},
	// stopCurrentBkgMusic: () => {},
	// mute: (muteCmd: boolean) => {},
	// preloadAllSounds: async () => {},
	// loaded: false,
	// speakSentence: (text:string, volume?:number, rate?:number) => {},
	// play: (soundName: TSound, volume?: number) => {},
}

const SoundContext = createContext<SoundContextProps | null>(null)

type SoundProviderProps = {
  children: ReactNode
};

const SoundProvider = ({ children }: SoundProviderProps) => {
	// const [activeBkgMusic, setActiveBkgMusic] = useState<Howl | null>(null)
	const [sounds, setSounds] = useState<Record<TSound, ISoundEffect> | null>(null)
	// const [_, setVoices] = useState<Array<SpeechSynthesisVoice>>([])
	// const [voice, setVoice] = useState<SpeechSynthesisVoice | null>(null)
	const [synth] = useState<SpeechSynthesis>(() => window.speechSynthesis)

	// const speech = useMemo(() => new SpeechSynthesisUtterance(), [])
	// speech.lang = 'en'
	// let	voices = window.speechSynthesis.getVoices()

	// let voices: Array<SpeechSynthesisVoice> = []
	// window.speechSynthesis.onvoiceschanged = () => {
	// 	voices = window.speechSynthesis.getVoices()
	// 	// speech.voice = voices[0]
	// }

	useEffect(() => {
		window.speechSynthesis.getVoices()

		// window.speechSynthesis.onvoiceschanged = () => {
		// 	const voices = window.speechSynthesis.getVoices()
		// 	console.log(voices.length)
		// 	if (voices.length !== 0) {
		// 		console.log('setting voice')
		// 		setVoice(voices[0])
		// 	}

		// 	console.log(voices.length)
		// 	setVoices(voices)
		// 	// 	console.log('setting voice')
		// 	// const voices = window.speechSynthesis.getVoices()
		// 	console.log(voices.length)

		// // speech.voice = voices[0];
		//  }
		// window.speechSynthesis.onvoiceschanged = () => {
		// 	console.log('setting voice')
		// const voices = window.speechSynthesis.getVoices()

		// setVoice(voices[0])
		// speech.voice = voices[0];
		// }

		// window.speechSynthesis.getVoices()
		// if (voices.length !== 0) {
		// 	console.log('setting voice')
		// 	setVoice(voices[0])
		// }
	}, [])

	// const [loaded, setLoaded] = useState<boolean>(false)

	/* const play = useCallback((soundName: TSound, volume = 0.1):void => {
		console.log(`play ${soundName}`)
		// console.log(`sounds have been preloaded? ${loaded}`)
		// if (loaded) {
		let sound
		const data = sounds[soundName]
		console.log('play, data?.howl=', data?.howl)

		if (data.howl) {
			sound = data.howl
			// console.log('sound.state()=', sound.state())
			// if (sound.state() === 'loaded') {
			// Begin playing the sound.
			sound.seek(0)
			sound.volume(volume)
			// sound.loop(false)
			sound.play()
			// }
		}
		// }

		// Show the pause button.
	}, [sounds])
 */
	// }, [sounds, loaded])

	// useEffect(() => {
	// 	Howler.mute(true)
	// }, [])

	// const mute = useCallback((muteCmd: boolean):void => {
	// 	Howler.mute(muteCmd)
	// 	// setMuted(muteCmd)
	// }, [])

	/* 	const stopCurrentBkgMusic = useCallback(() => {
		// if (activeBkgMusic) {
		// console.log('stopping music')
		// console.log(activeBkgMusic)
		activeBkgMusic?.stop()
		// }
	}, [activeBkgMusic])

	const streamMusic = useCallback((soundName: TStreamingSoundName, volume = 0.1):void => {
		// console.log(`streamMusic: ${soundName}`)

		if (activeBkgMusic) {
			activeBkgMusic.stop()
		}

		const { file } = allStreamingSounds[soundName]
		const howl = new Howl({
			src: [`/assets/demo/sound/${file}.mp3`],
			html5: true,
			loop: true,
			autoplay: true,
			volume,
		})

		setActiveBkgMusic(howl)

		// Show the pause button.
	}, [activeBkgMusic]) */

	/* 	useEffect(() => () => {
		// console.log('unmount')
		stopCurrentBkgMusic()

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) */

	// const preloadAllSounds = useCallback(, [sounds])

	const value = useMemo(() => ({
		// streamMusic,
		// stopCurrentBkgMusic,
		preloadAllSounds: ():void => {
			// console.log('preload all sounds')
			const newSounds = Object.entries(allPreloadSounds).map(([key, soundeffect]) => ({
				...soundeffect,
				name: key,
				player: new Audio(`/assets/demo/sound/${soundeffect.file}`),
			} as ISoundEffect))

			const newSounds2 = keyBy(newSounds, 'name') as unknown as Record<TSound, ISoundEffect>
			setSounds(newSounds2)
		},

		//

		play: (soundName: TSound, volume = 0.1):void => {
			// console.log(`play ${soundName}`)
			// console.log(`sounds have been preloaded? ${loaded}`)
			// if (loaded) {
			let sound

			if (sounds) {
				const data = sounds[soundName]
				// console.log('play, data?.howl=', data?.player)

				if (data?.player) {
					const { player } = data
					player.loop = false
					player.volume = volume
					player.play()
				}
			}
		},

		//

		speakSentence: (text:string, volume?: number, rate?: number, pitch?: number):void => {
			const voices = window.speechSynthesis.getVoices()
			if (voices.length !== 0) {
				console.log('speak', text)
				const utterThis = new SpeechSynthesisUtterance(text)
				// eslint-disable-next-line prefer-destructuring
				utterThis.voice = voices[0]
				// const speech = new SpeechSynthesisUtterance()
				// speech.lang = 'en'
				// utterThis.voice = voice
				utterThis.rate = rate ?? 1.7 // 0.1 to 10
				utterThis.pitch = pitch ?? 1.8 // 0 to 2
				utterThis.volume = volume ?? 0.5
				// speech.text = text
				synth.speak(utterThis)
			}
		},
		sounds,
		// play,
	} as SoundContextProps), [sounds, synth])

	return (
		<SoundContext.Provider value={value}>
			{children}
		</SoundContext.Provider>
	)
}

export { SoundProvider, SoundContext }
