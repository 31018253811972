import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { IWebAppState, TView } from 'src/@types/webapp'

// ----------------------------------------------------------------------

const initialState: IWebAppState = {
	view: 'user_profile',
}

const slice = createSlice({
	name: 'webapp',
	initialState,
	reducers: {
		setView(state, action) {
			state.view = action.payload
		},

	},
})

// Reducer
export default slice.reducer

export const { actions } = slice

// ----------------------------------------------------------------------

interface SetViewProps {
	view: TView
}
export function setView({ view }: SetViewProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setView(view))
	}
}
