import { createSlice, Dispatch } from '@reduxjs/toolkit'
import {
	getDatabase, ref, child, push, update, set, remove, onValue, query, Unsubscribe,
} from 'firebase/database'
import { logger } from 'src/services/logger'
import { IUserInterest, TInterestsState } from 'src/types/Interests'
// ----------------------------------------------------------------------

const initialState: TInterestsState = {
	isLoading: false,
	isLoaded: false,
	error: false,
	allInterests: [
		{ id: '0', label: 'Sustainability' },
		{ id: '1', label: 'Environment' },
		{ id: '2', label: 'Sports' },
		{ id: '3', label: 'Health & Fitness' },
		{ id: '4', label: 'Geek Stuff' },
		{ id: '5', label: 'Social Justice' },
		{ id: '6', label: 'Gaming' },
		{ id: '7', label: 'Technology' },
		{ id: '8', label: 'Anime' },
		{ id: '9', label: 'Art' },
		{ id: '10', label: 'Music' },
		{ id: '11', label: 'Fashion' },
		{ id: '12', label: 'Food' },
		{ id: '13', label: 'Travel' },
		{ id: '14', label: 'Vintage' },
		{ id: '15', label: 'Politics' },
		{ id: '16', label: 'Entrepreneurship' },
		{ id: '17', label: 'Vampires' },
		{ id: '18', label: 'Superheroes' },
	],
	myInterests: [],
}

const slice = createSlice({
	name: 'interests',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoaded = false
			state.isLoading = true
			state.error = false
		},

		// HAS ERROR
		getFailure(state) {
			state.isLoaded = false
			state.isLoading = false
			state.error = true
		},

		// GET INTERESTS
		getSuccess(state, action) {
			const { interests } = action.payload
			state.isLoaded = true
			state.isLoading = false
			state.myInterests = interests
			state.error = false
		},

		// Add INTEREST
		addInterest(state, action) {
			const { interest, interest: { id } } = action.payload
			if (!state.myInterests.find((interest) => interest.id === id)) {
				state.myInterests.push(interest)
			}
		},

		// Remove INTEREST
		removeInterest(state, action) {
			const { interest, interest: { id } } = action.payload
			const idx = state.myInterests.findIndex((interest) => interest.id === id)
			if (idx >= 0) {
				state.myInterests.splice(idx, 1)
			}
		},
	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface GetInterestsProps {
	uid: string
}
export const getInterests = ({ uid }:GetInterestsProps) => (dispatch:Dispatch): Unsubscribe | undefined => {
	dispatch(slice.actions.startLoading())
	try {
		logger.log('getInterests')
		const db = getDatabase()
		const collectionRef = ref(db, `users/${uid}/interests`)
		// const q = query(collectionRef)
		const unsubscribe: Unsubscribe = onValue(collectionRef, (querySnapshot) => {
			const interests:Array<IUserInterest> = []
			if (querySnapshot.exists()) {
				logger.log('querySnapshot=', querySnapshot)
				querySnapshot.forEach((childSnapshot) => {
				// const childKey = childSnapshot.key
					const interest: IUserInterest = childSnapshot.val() as IUserInterest
					interests.push(interest)
				})
			} else {
				logger.log('no interest data found')
			}

			dispatch(slice.actions.getSuccess({
				interests,
			}))
		})

		return unsubscribe
	} catch (error) {
		logger.error(error)
		dispatch(slice.actions.getFailure())
	}
	return undefined
}

// ----------------------------------------------------------------------

interface AddInterestProps {
	interest: IUserInterest
	uid: string
}

export function addInterest(props: AddInterestProps) {
	return async (dispatch: Dispatch) => {
		const { uid, interest } = props
		dispatch(slice.actions.addInterest({ interest }))
		const db = getDatabase()
		const docRef = ref(db, `users/${uid}/interests/${interest.id}`)

		await set(docRef, {
			...interest,
		})
	}
}

// ----------------------------------------------------------------------

interface RemoveInterestProps {
	uid: string
	interest: IUserInterest
}
export function removeInterest(props: RemoveInterestProps) {
	return async (dispatch: Dispatch) => {
		const { uid, interest } = props
		dispatch(slice.actions.removeInterest({ interest }))
		const db = getDatabase()
		const docRef = ref(db, `users/${uid}/interests/${interest.id}`)

		await remove(docRef)
	}
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

interface SetPreferncesProps {
	uid: string
	preferenceName: string
	values: Array<string>
}
export function setPreferences(props: SetPreferncesProps) {
	return async (dispatch: Dispatch) => {
		try {
			const { uid, preferenceName, values } = props
			logger.log('setLikes', props)

			const db = getDatabase()
			const docRef = ref(db, `users/${uid}/${preferenceName}`)

			await set(docRef, {
				values,
			})
		} catch (error) {
			logger.error(error)
		}
	}
}
