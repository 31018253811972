// import firebase from 'gatsby-plugin-firebase-v9.0-typescript'

import { Timestamp } from 'firebase/firestore'
import { localhostAlias } from 'src/config'

export const toDate = (firebaseTimestamp:Timestamp):Date => {
	const { seconds, nanoseconds } = firebaseTimestamp
	return new Timestamp(seconds, nanoseconds).toDate()
}

export const getApiUrl = (functionName:string):string => {
// emulator example format: http://localhost:5005/stickes-dev/us-central1
// production example format: https://us-central1-ureally-prod.cloudfunctions.net
	const domain = process.env.REACT_APP_USE_EMULATOR === 'true'
		? `http://${localhostAlias}:${process.env.REACT_APP_FB_FUNCTIONS_PORT}/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/${process.env.REACT_APP_FIREBASE_REGION}`
		: `https://${process.env.REACT_APP_FIREBASE_REGION}-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`
	return `${domain}/${functionName}`
}
