import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { IDemoState, TAuthView, TTab } from 'src/@types/demo'
import { IViewableStory } from 'src/@types/storiesViewable'
import { ConsoleLogger, logger } from 'src/services/logger'
import { TFeedItem } from 'src/types/Feed'
import { IPromptSuggestionWithUserInfo } from 'src/types/PromptSuggestions'
import { IReprompt } from 'src/types/Prompts'
import { TPageView } from 'src/types/views'

type TOpenDrawerPayload = {
	view: TPageView
	peopleProfileUid?: string | null
	spaceId?: string | null
	promptSuggestion?: IPromptSuggestionWithUserInfo | null
	feedItem?: TFeedItem | null
}

type TOpenThreadsDrawerPayload = {
	// showThreadsWithIds?: Array<string>
	// showThreadsWithUid?: string | null
	showThreadId?: string | null
	// reprompt?: IReprompt | null
}

type TOpenBantrDrawerPayload = {
	spaceId: string | null
	friendUid: string | null
}

type TOpenFeedItemDrawerPayload = {
	// feedItemId?: string
	feedItem?: TFeedItem
}

type TOpenFeedItemReplyDrawerPayload = {
	feedItem?: TFeedItem
}

type TSetActiveTabPayload = {
	tab: TTab
}

type TShowRealtimeDrawerPayload = {
	spaceId?: string
}

type TShowDecisionHubDrawerPayload = {
	priorityId?: string | null
}
// ----------------------------------------------------------------------

const initialState: IDemoState = {
	isMuted: false,
	isThemeMusicEnabled: false,
	isAuthorDrawerOpen: false,
	isActionsDrawerOpen: false,
	isInfoDrawerOpen: false,
	selectedStory: null,
	isSuggestingLogin: false,
	suggestedAuthView: null,
	signInCaption: null,
	isShowingInfoBar: false,
	infoBarCaption: null,
	informationDrawerContent: null,
	drawerContents: null,
	// showPromptsForUid: null,
	// showThreadsWithUid: null,
	peopleProfileUid: null,
	promptSuggestion: null,
	// reprompt: null,
	activeTab: 'news',
	// showThreadsDrawer: false,
	// showThreadsWithIds: null,
	showThreadId: null,
	showFeedItem: null,
	showFeedItemReply: null,
	feedItem: null,
	showRealtime: false,
	showDecisionHub: false,
	showDecisionHubNodeId: null,
	gotoSpaceId: null,
	bantrSpaceId: null,
	bantrFriendUid: null,
	showBantrDrawer: false,
}

const slice = createSlice({
	name: 'demo',
	initialState,
	reducers: {
		// MUTE
		mute(state) {
			state.isMuted = true
		},

		unmute(state) {
			state.isMuted = false
		},

		enableThemeMusic(state) {
			state.isThemeMusicEnabled = true
		},

		disableThemeMusic(state) {
			state.isThemeMusicEnabled = false
		},

		authorDrawerSetOpen(state, action) {
			state.isAuthorDrawerOpen = action.payload
		},

		actionsDrawerSetOpen(state, action) {
			state.isActionsDrawerOpen = action.payload
		},

		infoDrawerSetOpen(state, action) {
			state.informationDrawerContent = action.payload
			state.isInfoDrawerOpen = true
		},

		infoDrawerSetClosed(state) {
			state.informationDrawerContent = null
			state.isInfoDrawerOpen = false
		},

		setSelectedStory(state, action) {
			state.selectedStory = action.payload
		},

		setSuggestLogin(state, action) {
			const { caption = null, suggest } = action.payload
			state.isSuggestingLogin = suggest
			state.signInCaption = suggest ? caption : null
		},

		setShowInfoBar(state, action) {
			const { caption = null, show } = action.payload
			state.isShowingInfoBar = show
			state.infoBarCaption = show ? caption : null
		},

		setSuggestedAuthView(state, action) {
			state.suggestedAuthView = action.payload
		},

		openDrawer(state, action) {
			const {
				view, peopleProfileUid = null, spaceId = null, feedItem = null,
				promptSuggestion = null,
			}:TOpenDrawerPayload = action.payload

			state.informationDrawerContent = action.payload
			state.drawerContents = view
			// state.showThreadsWithUid = showThreadsWithUid
			state.peopleProfileUid = peopleProfileUid
			state.spaceProfileId = spaceId
			// state.showThreadId = showThreadId
			state.promptSuggestion = promptSuggestion
			state.feedItem = feedItem
		},

		closeDrawer(state) {
			state.drawerContents = null
			// state.showThreadsWithUid = null
			state.peopleProfileUid = null
		},

		openThreadsDrawer(state, action) {
			const {		showThreadId }:TOpenThreadsDrawerPayload = action.payload
			state.showThreadId = showThreadId ?? null
		},

		closeThreadsDrawer(state) {
			state.showThreadId = null
		},

		openBantrDrawer(state, action) {
			logger.log('here')
			const {		spaceId = null, friendUid = null }:TOpenBantrDrawerPayload = action.payload
			state.showBantrDrawer = true
			state.bantrSpaceId = spaceId
			state.bantrFriendUid = friendUid
		},

		// -------------------------------

		openFeedItemDrawer(state, action) {
			const {
				// feedItemId,
				feedItem,
			}:TOpenFeedItemDrawerPayload = action.payload

			// state.showFeedItemId = feedItemId ?? null
			state.showFeedItem = feedItem ?? null
		},

		closeFeedItemDrawer(state) {
			state.showFeedItem = null
		},

		// -------------------------------

		openFeedItemReplyDrawer(state, action) {
			const {
				feedItem,
			}:TOpenFeedItemReplyDrawerPayload = action.payload

			// state.showFeedItemId = feedItemId ?? null
			state.showFeedItemReply = feedItem ?? null
		},

		closeFeedItemReplyDrawer(state) {
			state.showFeedItemReply = null
		},

		setActiveTab(state, action) {
			const {
				tab,
			}:TSetActiveTabPayload = action.payload

			state.activeTab = tab
		},

		showRealtimeDrawer(state, action) {
			const {
				spaceId,
			}:TShowRealtimeDrawerPayload = action.payload
			state.showRealtime = true
			state.gotoSpaceId = spaceId || null
		},

		hideRealtimeDrawer(state) {
			state.showRealtime = false
		},

		showDecisionHubDrawer(state, action) {
			const {
				priorityId = null,
			}:TShowDecisionHubDrawerPayload = action.payload
			state.showDecisionHub = true
			state.showDecisionHubNodeId = priorityId
		},

		closeDecisionHubDrawer(state) {
			state.showDecisionHub = false
		},

		closeBantrDrawer(state) {
			state.showBantrDrawer = false
			state.bantrFriendUid = null
			state.bantrSpaceId = null
		},

	},
})

// Reducer
export default slice.reducer

export const { actions } = slice

// export const {
// 	setActiveThread,
// } = slice.actions

// ----------------------------------------------------------------------

export function setMute() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.mute())
	}
}

// ----------------------------------------------------------------------

export function setUnMute() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.unmute())
	}
}

// ----------------------------------------------------------------------

export function setEnableThemeMusic() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.enableThemeMusic())
	}
}

// ----------------------------------------------------------------------

export function setDisableThemeMusic() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.disableThemeMusic())
	}
}

export function setAuthorDrawerOpen() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.authorDrawerSetOpen(true))
	}
}

export function setAuthorDrawerClosed() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.authorDrawerSetOpen(false))
	}
}

export function setSelectedStory(story: IViewableStory) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setSelectedStory(story))
	}
}

interface SetSuggestLoginProps {
	suggest: boolean
	caption?: string | null
}
export function setSuggestLogin({ suggest, caption = null }: SetSuggestLoginProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setSuggestLogin({ suggest, caption }))
	}
}

interface InfoBarProps {
	show: boolean
	caption?: string
}
export function setShowInfoBar(props: InfoBarProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setShowInfoBar(props))
	}
}

export function setSuggestedAuthView(view:TAuthView | null) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setSuggestedAuthView(view))
	}
}

export function setActionsDrawerOpen() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.actionsDrawerSetOpen(true))
	}
}

export function setActionsDrawerClosed() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.actionsDrawerSetOpen(false))
	}
}

// ----------------------------------------------------------------------

export function setInfoDrawerOpen(content: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.infoDrawerSetOpen(content))
	}
}

export function setInfoDrawerClosed() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.infoDrawerSetClosed())
	}
}

// ----------------------------------------------------------------------

interface OpenDrawerProps {
	view:TPageView
	// showPromptsForUid?: string
	// showThreadsWithUid? : string
	peopleProfileUid?: string
	spaceId?: string
	// showPromptId?: string
	promptSuggestion?: IPromptSuggestionWithUserInfo
	feedItem?: TFeedItem

}

export function openDrawer(props: OpenDrawerProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.openDrawer(props))
	}
}

export function closeDrawer() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.closeDrawer())
	}
}

export function closeThreadsDrawer() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.closeThreadsDrawer())
	}
}

interface OpenThreadsDrawerProps {
	// showThreadsWithUid? : string
	// showThreadsWithIds?: Array<string>
	showThreadId?: string
	// reprompt?: IReprompt
}
export function openThreadsDrawer(props: OpenThreadsDrawerProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.openThreadsDrawer(props))
	}
}

interface OpenRealTimeBantrDrawerProps {
	spaceId: string
	friendUid: string
}
export function openRealTimeBantrDrawer(props?: OpenRealTimeBantrDrawerProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.openBantrDrawer(props ?? {}))
	}
}

// ----------------------------------------
interface OpenFeedItemDrawerProps {
	// feedItemId: string
	feedItem: TFeedItem
}
export function openFeedItemDrawer(props: OpenFeedItemDrawerProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.openFeedItemDrawer(props))
	}
}

export function closeFeedItemDrawer() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.closeFeedItemDrawer())
	}
}

// ----------------------------------------
interface OpenFeedItemReplyDrawerProps {
	feedItem: TFeedItem
}
export function openFeedItemReplyDrawer(props: OpenFeedItemReplyDrawerProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.openFeedItemReplyDrawer(props))
	}
}

export function closeFeedItemReplyDrawer() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.closeFeedItemReplyDrawer())
	}
}

// ---------------------------------------

interface SetActiveTabProps {
	tab: TTab
}
export function setActiveTab({ tab }: SetActiveTabProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setActiveTab({ tab }))
	}
}

// ----------------------------------------------------------------------
