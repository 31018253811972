// ----------------------------------------------------------------------

import { siteData } from 'src/config'

function path(root: string, sublink: string) {
	return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'
// const ROOTS_NFTS = '/nfts'
const ROOTS_DEMOS = '/demos'
// const ROOTS_WEBAPP = '/webapp/inside'
const ROOTS_AI_DEMOS = '/'
const ROOTS_PLAYTESTS = '/playtests'
const ROOTS_LEGAL = '/legal'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
	root: ROOTS_AUTH,
	login: path(ROOTS_AUTH, '/login'),
	waitForNativeLogin: path(ROOTS_AUTH, '/waitfornativelogin'),
	// loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
	register: path(ROOTS_AUTH, '/register'),
	// registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
	resetPassword: path(ROOTS_AUTH, '/reset-password'),
	// verify: path(ROOTS_AUTH, '/verify'),

	// connectWallet: path(ROOTS_AUTH, '/connect'),
}

export const PATH_PAGE = {
	webapp: '/',
	comingSoon: '/coming-soon',
	maintenance: '/maintenance',
	pricing: '/pricing',
	// payment: '/payment',
	about: '/about',
	team: '/team',
	contact: '/support/contact',
	guidelines: '/support/guidelines',
	// faqs: '/faqs',
	page403: '/403',
	page404: '/404',
	page500: '/500',
	components: '/components',
	tos: '/legal/terms',
	privacy: '/legal/privacy',
	whitelistSignUp: '/whitelist',
	collections: '/collections',
	collectionsSignUp: '/collections/register',
	nftOwners: '/nfts',
	characters: '/characters',
	previewStory: (storyId: string, queryParams = '') => `/${storyId}${queryParams}`,
	story: (storyId: string) => `${siteData.siteRootUrl}/${storyId}`,
	wiki: (deepLink: string) => `${siteData.wikiForCollectors}/${deepLink}`,
	wikiNotion: (deepLink: string) => `${siteData.wikiNotion}/${deepLink}`,
	hubspotForm: `${siteData.hubspotForm}`,
	// feedback: (deepLink: string) => `${siteData.wikiForCollectors}/${deepLink}`,
	// stories: '/stories',
	// learn: '/learn',
	// demos: '/demos',
	// legal: '/legal',
}

export const PATH_DEMOS = {
	root: ROOTS_DEMOS,
	general: {
		chats: path(ROOTS_DEMOS, '/chats'),
		spawns: path(ROOTS_DEMOS, '/spawns'),
	},
	spawn: {
		root: path(ROOTS_DEMOS, '/spawn/:chain/:address/:tokenId'),
	},
}

export const PATH_NFT_PROFILE = {
	profile: (handle: string) => path('/', `${handle}`),
}

export const PATH_NFT_AI_DEMO = {
	profile: (customTokenId: string) => `${siteData.siteRootUrl}${path(ROOTS_AI_DEMOS, `${customTokenId}`)}`,
}

export const PATH_PLAYTESTS = {

	root: ROOTS_PLAYTESTS,
	browse: path(ROOTS_PLAYTESTS, '/tests/browse'),
	test: (id: string) => path(ROOTS_PLAYTESTS, `/tests/epic/${id}`),
}

export const PATH_LEGAL = {
	root: ROOTS_LEGAL,
	general: {
		privacy: path(ROOTS_LEGAL, '/privacy'),
		terms: path(ROOTS_LEGAL, '/terms'),
	},
}

export const PATH_DASHBOARD = {
	root: ROOTS_DASHBOARD,
	general: {
		app: path(ROOTS_DASHBOARD, '/app'),
		// ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
		train: path(ROOTS_DASHBOARD, '/train'),
		configure: path(ROOTS_DASHBOARD, '/nfts/nft/{{ id }}'),
		nfts: path(ROOTS_DASHBOARD, '/nfts'),

		// analytics: path(ROOTS_DASHBOARD, '/analytics'),
		// banking: path(ROOTS_DASHBOARD, '/banking'),
		// booking: path(ROOTS_DASHBOARD, '/booking'),
	},
	wallets: path(ROOTS_DASHBOARD, '/wallets'),
	npc: {
		root: path(ROOTS_DASHBOARD, '/npc'),
		app: path(ROOTS_DASHBOARD, '/app'),
		settings: path(ROOTS_DASHBOARD, '/npc/settings'),
		usage: path(ROOTS_DASHBOARD, '/npc/usage'),
		ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
		analytics: path(ROOTS_DASHBOARD, '/npc/analytics'),
		social: path(ROOTS_DASHBOARD, '/social'),
		booking: path(ROOTS_DASHBOARD, '/booking'),
		profile: path(ROOTS_DASHBOARD, '/npc/profile'),
		// newPhrase: path(ROOTS_DASHBOARD, '/npc/phrase/new')
	},
	phrase: {
		root: path(ROOTS_DASHBOARD, '/npc/phrase'),
		shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
		product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
		productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
		list: path(ROOTS_DASHBOARD, '/npc/phrase/list'),
		newPhrase: path(ROOTS_DASHBOARD, '/npc/phrase/new'),
		editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
		checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
		invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
	},
	mail: {
		root: path(ROOTS_DASHBOARD, '/mail'),
		all: path(ROOTS_DASHBOARD, '/mail/all'),
	},
	chat: {
		root: path(ROOTS_DASHBOARD, '/chat'),
		new: path(ROOTS_DASHBOARD, '/chat/new'),
		conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
		view: (id: string) => path(ROOTS_DASHBOARD, `/nfts/nft/${id}`),
	},
	calendar: path(ROOTS_DASHBOARD, '/calendar'),
	kanban: path(ROOTS_DASHBOARD, '/kanban'),
	permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
	user: {
		root: path(ROOTS_DASHBOARD, '/user'),
		new: path(ROOTS_DASHBOARD, '/user/new'),
		list: path(ROOTS_DASHBOARD, '/user/list'),
		cards: path(ROOTS_DASHBOARD, '/user/cards'),
		profile: path(ROOTS_DASHBOARD, '/user/profile'),
		// account: path(ROOTS_DASHBOARD, '/user/account'),
		account: path(ROOTS_DASHBOARD, '/user/account'),
		edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
		demoEdit: path(ROOTS_DASHBOARD, '/user/reece-chung/edit'),
	},
	eCommerce: {
		root: path(ROOTS_DASHBOARD, '/e-commerce'),
		shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
		list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
		checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
		new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
		view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
		edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
		demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
		demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
	},
	nfts: {
		root: ROOTS_DASHBOARD,
		// browse: path(ROOTS_NFTS, '/browse'),
		browse: path(ROOTS_DASHBOARD, '/nfts/browse'),
		view: (id: string) => path(ROOTS_DASHBOARD, `/nfts/nft/${id}`),
		// general: path(ROOTS_DASHBOARD, '/nfts/general'),
		general: path(ROOTS_DASHBOARD, '/nfts/{{ id }}/general'),
		// configure: (id: string) => `/nfts/${id}/configure`,
		edit: (customTokenId: string, tab?: string) => path(ROOTS_DASHBOARD, `/nfts/edit/${customTokenId}?tab=${tab}`),
		// edit: '/nfts/edit',
		// configure2: '/nfts/configure',
		// personalized: '/nfts/personalize',
		// mods: '/nfts/mods',
		// stories: path(ROOTS_DASHBOARD, '/nfts/stories'),
		stories: (customTokenId: string) => path(ROOTS_DASHBOARD, `/nfts/${customTokenId}/stories`),
		newStory: (customTokenId: string) => path(ROOTS_DASHBOARD, `/nfts/${customTokenId}/stories/new`),
		// editStory: (id: string) => path(ROOTS_DASHBOARD, `/nfts/story/${id}/edit`),
		editStory: (customTokenId: string, id: string) => path(ROOTS_DASHBOARD, `/nfts/${customTokenId}/stories/${id}/edit`),
		connectWallet: path(ROOTS_DASHBOARD, '/nfts/connect'),
		wallets: path(ROOTS_DASHBOARD, '/nfts/wallets'),
	},
	invoice: {
		root: path(ROOTS_DASHBOARD, '/invoice'),
		list: path(ROOTS_DASHBOARD, '/invoice/list'),
		new: path(ROOTS_DASHBOARD, '/invoice/new'),
		view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
		edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
		demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
		demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
	},
	// blog: {
	// 	root: path(ROOTS_DASHBOARD, '/blog'),
	// 	posts: path(ROOTS_DASHBOARD, '/blog/posts'),
	// 	new: path(ROOTS_DASHBOARD, '/blog/new'),
	// 	view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
	// 	demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
	// },
	spaces: {
		root: path(ROOTS_DASHBOARD, '/spaces'),
		all: path(ROOTS_DASHBOARD, '/spaces/all'),
		view: (id: string) => path(ROOTS_DASHBOARD, `/spaces/space/${id}`),
		// demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
	},
	train: {
		root: (id: string) => path(ROOTS_DASHBOARD, `/train/${id}`),
	},
}

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction'
