import { createSlice, Dispatch } from '@reduxjs/toolkit'
import {
	doc, getDoc, getFirestore, onSnapshot, query,
	increment, serverTimestamp, setDoc, Timestamp, collection, getDocs, where, addDoc, Unsubscribe, orderBy, limit, collectionGroup, startAfter, QueryDocumentSnapshot, DocumentData, getCountFromServer, FieldValue, deleteField, deleteDoc,
} from 'firebase/firestore'
import { ConsoleLogger, logger } from 'src/services/logger'

import {
	FeedState, IFeedItemComment, IFeedItemCommentLike, IFeedItemFromDb, TFeedItem,
} from 'src/types/Feed'

import { getFunctions, httpsCallable } from 'firebase/functions'
import { keyBy } from 'lodash'
import { firestoreToFeedItem } from 'src/utils/feed'
import { IThreadMessage } from 'src/types/ThreadMessages'
import { FIRESTORE } from 'src/config'
import { store } from '../store'
// ----------------------------------------------------------------------

export type TSort = 'byCreatedDesc'

type TGetPageSuccessPayload = {
	items: Array<TFeedItem>
	totalItems: number
	lastDoc: QueryDocumentSnapshot<DocumentData> | null
	pageSize: number
	reset: boolean
}

type TUpdateFeedItemPayload = {
	item: TFeedItem
}

// type TGetTotalMoveSuccessPayload = {
// 	total: number
// }

type TToggleLikePayload = {
	// uid: string
	item: TFeedItem
	isLiked: boolean
}

// type TToggleLikeOnMessagePayload = {
// 	item: TFeedItem
// 	message: IThreadMessage
// 	isLiked: boolean
// }

type TSetRepostedPayload = {
	item: TFeedItem
}

type TSetHasMadeCommentPayload = {
	item: TFeedItem
}

type TDeleteItemPayload = {
	item: TFeedItem
}

const initialState: FeedState = {
	isLoading: false,
	error: false,
	items: [],
	itemById: {},
	lastDoc: null,
	hasMore: true,
}

const slice = createSlice({
	name: 'feedv2',
	initialState,
	reducers: {

		startLoadingPage(state) {
			state.isLoading = true
			state.error = false
		},

		getPageFailure(state) {
			state.isLoading = false
			state.error = true
		},

		getPageSuccess(state, action) {
			const {
				lastDoc, items, pageSize, reset, totalItems,
			}: TGetPageSuccessPayload = action.payload

			state.lastDoc = lastDoc

			state.items = [
				...(!reset ? state.items : []),
				...items,
			]

			state.itemById = keyBy(state.items, 'id')
			state.hasMore = totalItems > state.items.length
			state.isLoading = false
			state.error = false
		},

		updateFeedItem(state, action) {
			const {
				item: targetedItem,
			}: TUpdateFeedItemPayload = action.payload

			// logger.log({ targetedItem })
			const idx = state.items.findIndex((item) => item.id === targetedItem.id)
			if (idx >= 0) {
				state.items.splice(idx, 1, targetedItem)
				state.itemById = keyBy(state.items, 'id')
			}
		},

		toggleLike(state, action) {
			const { item: targetedItem, isLiked }:TToggleLikePayload = action.payload
			const idx = state.items.findIndex((item) => item.id === targetedItem.id)
			if (idx >= 0) {
				const { likeCount = 0 } = state.items[idx]
				state.items[idx].isLiked = isLiked
				state.items[idx].likeCount = (likeCount + (isLiked ? 1 : -1))
				state.itemById = keyBy(state.items, 'id')
			}
		},

		// toggleLikeOnMessage(state, action) {
		// 	const { item: targetedItem, message: targetMessage, isLiked }:TToggleLikeOnMessagePayload = action.payload
		// 	const idx = state.items.findIndex((item) => item.id === targetedItem.id)
		// 	if (idx >= 0) {
		// 		const { likes = 0 } = state.items[idx]
		// 		state.items[idx].isLiked = isLiked
		// 		state.items[idx].likes = (likes + (isLiked ? 1 : -1))
		// 		state.itemById = keyBy(state.items, 'id')
		// 	}
		// },

		setReprompted(state, action) {
			const { item: targetedItem }:TSetRepostedPayload = action.payload
			const idx = state.items.findIndex((item) => item.id === targetedItem.id)
			if (idx >= 0) {
				const { repostCount = 0 } = state.items[idx]
				state.items[idx].isReposted = true
				state.items[idx].repostCount = repostCount + 1
				state.itemById = keyBy(state.items, 'id')
			}
		},

		setHasMadeComment(state, action) {
			const { item: targetedItem }:TSetHasMadeCommentPayload = action.payload
			const idx = state.items.findIndex((item) => item.id === targetedItem.id)
			if (idx >= 0) {
				state.items[idx].hasCommented = true
				state.itemById = keyBy(state.items, 'id')
			}
		},

		deleteItem(state, action) {
			const { item: targetedItem }:TDeleteItemPayload = action.payload
			const idx = state.items.findIndex((item) => item.id === targetedItem.id)
			if (idx >= 0) {
				if (idx >= 0) {
					state.items.splice(idx, 1)
					state.itemById = keyBy(state.items, 'id')
				}
			}
		},

	},
})

export const {
	updateFeedItem,
} = slice.actions
// Reducer
export default slice.reducer

// --------------------------------------------------

interface GetPageProps {
	pageSize: number
	uid: string
	reset?: boolean
}

export function getPage(props:GetPageProps) {
	return async () => {
		logger.log('feedv2, getPage', props)
		const { dispatch, getState } = store

		try {
			const {
				 pageSize, uid, reset = false,
			} = props

			if (reset) {
				dispatch(slice.actions.startLoadingPage())
			}

			// const x = getState()
			// logger.log({ currentTreeState: x })

			const { feed2: feedState }:{feed2: FeedState} = getState()
			const startAfterDoc: QueryDocumentSnapshot<DocumentData, DocumentData> | null = feedState?.lastDoc ?? null// as QueryDocumentSnapshot<DocumentData, DocumentData>
			const db = getFirestore()
			const collectionRef = collection(db, 'users', uid, 'feed_items')

			let q

			if (reset || !startAfterDoc) {
				q = query(
					collectionRef,
					orderBy('createdAt', 'desc'),
					limit(pageSize),
				)
			} else {
				q	 = query(
					collectionRef,
					orderBy('createdAt', 'desc'),
					limit(pageSize),
					startAfter(startAfterDoc),
				)
			}

			const querySnapshot = await getDocs(q)

			if (querySnapshot.size > 0) {
				logger.log('new page of feed ata')

				const queryForTotalItems = query(collectionRef)
				const totalFeedSizeQuerySnapshot = await getCountFromServer(queryForTotalItems)
				const totalItems = totalFeedSizeQuerySnapshot.data().count

				const items: Array<TFeedItem> = querySnapshot.docs.map((item) => firestoreToFeedItem(item))

				logger.log({ items })
				dispatch(slice.actions.getPageSuccess({
					items,
					totalItems,
					reset,
					lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
					pageSize,
				}))
			} else {
				dispatch(slice.actions.getPageSuccess({
					items: [],
					totalItems: 0,
					reset,
					lastDoc: null,
					pageSize,
				}))
			}
		} catch (err) {
			logger.error(err)
			dispatch(slice.actions.getPageFailure())
		}
	}
}

// ----------------------------------------------------------------------

interface ToggleLikeProps {
	uid: string
	item: TFeedItem
}
export function toggleLike(props: ToggleLikeProps) {
	return async (dispatch: Dispatch) => {
		const { uid, item } = props
		logger.log('toggleLike', props)
		const newIsLiked = !(item?.isLiked ?? false)

		dispatch(slice.actions.toggleLike({ item, isLiked: newIsLiked }))
		const db = getFirestore()
		const userFeedItemDocRef = doc(db, 'users', uid, 'feed_items', item.id)
		const rootFeedItemDocRef = doc(db, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEMS, item.rootFeedId)

		await Promise.all([

			//

			setDoc(userFeedItemDocRef, {
				isLiked: newIsLiked,
				...(newIsLiked ? {
					likedByUid: uid,
					likedAt: serverTimestamp(),
				} : {
					likedByUid: deleteField(),
					likedAt: deleteField(),
				}),
			}, { merge: true }),

			//

			setDoc(rootFeedItemDocRef, {
				likeCount: increment(newIsLiked ? 1 : -1),
				likesUpdatedAt: serverTimestamp(),
				// updatedAt: serverTimestamp(),
			}, { merge: true }),

			//

		])
	}
}

// ----------------------------------------------------------------------

interface ToggleLikeOnCommentProps {
	uid: string
	isLiked: boolean
	feedItem: TFeedItem
	comment: IFeedItemComment
}
export function toggleLikeOnComment(props: ToggleLikeOnCommentProps) {
	return async (dispatch: Dispatch) => {
		const {
			uid, feedItem, comment, isLiked,
		} = props
		logger.log('toggleLikeOnMessage', props)
		// const newIsLiked = !(feedItem?.isLiked ?? false)
		// const newIsLiked = !isLiked

		dispatch(slice.actions.toggleLike({ item: feedItem, isLiked }))
		const db = getFirestore()

		const commentDocRef = doc(db, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEMS, feedItem.rootFeedId, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEM_COMMENTS, comment.id)
		const userFeedItemCommentLikesDocRef = doc(db, 'users', uid, 'feed_items', feedItem.id, FIRESTORE.COLLECTIONS.FEED_ITEM_COMMENT_LIKES, comment.id)

		const feedItemCommentLike: Omit<IFeedItemCommentLike, 'id'> = {
			commentId: comment.id,
			likedByUid: uid,
			rootFeedId: feedItem.rootFeedId,
			feedItemId: feedItem.id,
			commentAuthorUid: comment.fromUid,
			postAuthorUid: feedItem.authorUid,
		}

		await Promise.all([

			//

			setDoc(commentDocRef, {
				likeCount: increment(isLiked ? 1 : -1),
			}, { merge: true }),
			//

			// setDoc(userFeedItemDocRef, {
			// 	isLiked,
			// 	updatedAt: serverTimestamp(),
			// 	...(isLiked ? {
			// 		likedAt: serverTimestamp(),
			// 	} : {
			// 		likedAt: deleteField(),
			// 	}),

			// }, { merge: true }),

			//

			isLiked
				? setDoc(userFeedItemCommentLikesDocRef, {
					// isLiked,
					...feedItemCommentLike,
					// updatedAt: serverTimestamp(),
					...(isLiked ? {
						likedAt: serverTimestamp(),
					} : {
						likedAt: deleteField(),
					}),

				}, { merge: true })
				: deleteDoc(userFeedItemCommentLikesDocRef),

			//

		])
	}
}

// ----------------------------------------------------------------------

// interface SetRepromptedProps {
// 	uid: string
// 	item: TFeedItem
// }
// export function setReprompted(props: SetRepromptedProps) {
// 	return async (dispatch: Dispatch) => {
// 		const { uid, item } = props
// 		logger.log('setReprompted', props)
// 		// const newIsLiked = !(item?.isLiked ?? false)

// 		dispatch(slice.actions.setReprompted({ item }))
// 		const db = getFirestore()
// 		const userFeedItemDocRef = doc(db, 'users', uid, 'feed_items', item.id)
// 		const rootFeedItemDocRef = doc(db, 'root_feed_items', item.rootFeedId)

// 		await Promise.all([

// 			//

// 			setDoc(userFeedItemDocRef, {
// 				isReprompted: true,
// 				updatedAt: serverTimestamp(),
// 				repromptedAt: serverTimestamp(),
// 			}, { merge: true }),

// 			//

// 			setDoc(rootFeedItemDocRef, {
// 				reprompts: increment(1),
// 				repromptsUpdatedAt: serverTimestamp(),
// 				updatedAt: serverTimestamp(),
// 			}, { merge: true }),

// 			//

// 		])
// 	}
// }

// ----------------------------------------------------------------------

interface ReplyProps {
	uid: string
	text: string
	feedItem: TFeedItem
}
export function reply(props: ReplyProps) {
	return async (dispatch: Dispatch) => {
		const { uid, text, feedItem } = props
		logger.log('reply', props)

		// dispatch(slice.actions.setReprompted({ item }))

		const db = getFirestore()

		const commentCollectionRef = collection(db, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEMS, feedItem.rootFeedId, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEM_COMMENTS)
		const rootFeedItemDocRef = doc(db, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEMS, feedItem.rootFeedId)
		const userFeedItemDocRef = doc(db, 'users', uid, 'feed_items', feedItem.id)
		await Promise.all([

			//

			addDoc(commentCollectionRef, {
				fromUid: uid,
				text,
				createdAt: serverTimestamp(),
			}),

			setDoc(rootFeedItemDocRef, {
				commentCount: increment(1),
				commentsUpdatedAt: serverTimestamp(),
			}, { merge: true }),

			setDoc(userFeedItemDocRef, {
				hasCommented: true,
			}, { merge: true }),
			//
		])
	}
}

// ----------------------------------------------------------------------

interface ReportProps {
	uid: string
	item: TFeedItem
}
export function report(props: ReportProps) {
	return async (dispatch: Dispatch) => {
		const { uid, item } = props
		logger.log('report', props)
		// const newIsLiked = !(item?.isLiked ?? false)

		dispatch(slice.actions.deleteItem({ item }))
		const db = getFirestore()
		const userFeedItemDocRef = doc(db, 'users', uid, 'feed_items', item.id)
		const rootFeedItemDocRef = doc(db, 'root_feed_items', item.rootFeedId)

		await Promise.all([

			//

			deleteDoc(userFeedItemDocRef),

			//

			setDoc(rootFeedItemDocRef, {
				reported: increment(1),
				updatedAt: serverTimestamp(),
			}, { merge: true }),

			//

		])
	}
}

// ----------------------------------------------------------------------

interface DeleteFeedItemProps {
	uid: string
	item: TFeedItem
}
export function deleteFeedItem(props: DeleteFeedItemProps) {
	return async (dispatch: Dispatch) => {
		const { uid, item } = props
		logger.log('deleteFeedItem', props)

		dispatch(slice.actions.deleteItem({ item }))
		const db = getFirestore()
		// const userFeedItemDocRef = doc(db, 'users', uid, 'feed_items', item.id)
		const rootFeedItemDocRef = doc(db, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEMS, item.rootFeedId)

		await Promise.all([

			//

			// deleteDoc(userFeedItemDocRef),

			//

			deleteDoc(rootFeedItemDocRef),

			//

		])
	}
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

interface DeleteCommentProps {
	uid: string
	comment: IFeedItemComment
	feedItem: TFeedItem
}
export function deleteComment(props: DeleteCommentProps) {
	return async (dispatch: Dispatch) => {
		const { uid, comment, feedItem } = props
		// logger.log('deleteComment', props)

		// dispatch(slice.actions.deleteItem({ item }))
		const db = getFirestore()
		// const userFeedItemDocRef = doc(db, 'users', uid, 'feed_items', item.id)
		const commentCollectionRef = collection(db, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEMS, feedItem.rootFeedId, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEM_COMMENTS)
		const rootFeedItemDocRef = doc(db, 'root_feed_items', feedItem.rootFeedId)
		const commentDocRef = doc(db, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEMS, feedItem.rootFeedId, FIRESTORE.COLLECTIONS.ROOT_FEED_ITEM_COMMENTS, comment.id)
		const userFeedItemDocRef = doc(db, 'users', uid, 'feed_items', feedItem.id)

		const q = query(
			commentCollectionRef,
			where('fromUid', '==', uid),
		)

		const totalCommentsByUserQuerySnapshot = await getCountFromServer(q)
		const totalCommentsByUser = totalCommentsByUserQuerySnapshot.data().count

		logger.log({ totalCommentsByUser })
		await Promise.all([

			setDoc(rootFeedItemDocRef, {
				commentCount: increment(-1),
				commentsUpdatedAt: serverTimestamp(),
			}, { merge: true }),

			setDoc(userFeedItemDocRef, {
				hasCommented: (totalCommentsByUser - 1) > 0, // we subtract 1 since we are about to delete 1 comment
			}, { merge: true }),

			deleteDoc(commentDocRef),

			//

		])
	}
}

// ----------------------------------------------------------------------

interface SetHasMadeMoveProps {
	uid: string
	item: TFeedItem
}
export function setHasMadeComment(props: SetHasMadeMoveProps) {
	return async (dispatch: Dispatch) => {
		const { uid, item } = props
		logger.log('setHasMadeMove', props)

		dispatch(slice.actions.setHasMadeComment({ item }))
		const db = getFirestore()
		const userFeedItemDocRef = doc(db, 'users', uid, 'feed_items', item.id)

		await Promise.all([
			setDoc(userFeedItemDocRef, {
				hasMadeComment: true,
			}, { merge: true }),
		])
	}
}

// ----------------------------------------------------------------------
