import { useContext } from 'react'
import { MixpanelContext } from '../contexts/MixpanelContext'

// ----------------------------------------------------------------------

export enum MixpanelPropertyName {
	EventType = 'EventType',
}

export enum MixpanelEvents {
	// new App
	AppLoad = 'AppLoad',
	OpenDeepLinkToThreadViaAPN = 'OpenDeepLinkToThreadViaAPN',
	OpenDeepLinkToFeedItemViaAPN = 'OpenDeepLinkToFeedItemViaAPN',
	FriendSelected = 'FriendSelected',
	PromptSent = 'PromptSent',
	ViewFeed = 'ViewFeed',
	ViewAddPeople = 'ViewAddPeople',
	ViewChatList = 'ViewChatList',
	DoPeopleSearch = 'DoPeopleSearch',
	ConfiguredToken = 'ConfiguredToken',
	CustomTokenClick = 'CustomTokenClick',
	SpaceClick = 'SpaceClick',
	FeedNextPage = 'FeedNextPage',
	FeedFirstPage = 'FeedFirstPage',
	StoryFeedFirstPage = 'StoryFeedFirstPage',
	StoryFeedNextPage = 'StoryFeedNextPage',
	StoryFeedSpecificStory = 'StoryFeedSpecificStory',
	StoryImpression = 'StoryImpression',
	StoryEngagement = 'StoryEngagement',
	JoinWWhitelistButton = 'JoinWhitelistButton',
	LoginButton = 'LoginButton',
	LogoutButton = 'LogoutButton',
	Like = 'Like',
	Share = 'Share',
	AddToInventory = 'AddToInventory',
	ChangeJob = 'ChangeJob',
	HoverOverPFP = 'HoverOverPFP',
	HoverOverPromotedItem = 'HoverOverPromotedItem',
	HoverOverSpace = 'HoverOverSpace',
	PageView = 'PageView',
	MailChimp = 'MailChimp',
	Back = 'Back',
	ShowComments = 'ShowComments',
	PasteUrlForImport = 'PasteUrlForImport',
	ShareMenuOpened = 'ShareMenuOpened',
	GetStarted = 'GetStarted',
	FollowUnfollowNFT = 'FollowUnfollowNFT',
	LikeUnlikeStory = 'LikeUnlikeStory',
	Unmute = 'Unmute',
	AvatarClick = 'AvatarClick',
	SuggestSignInDialogue = 'SuggestSignInDialogue',
	ChangeWebAppTabInToolbar = 'ChangeWebAppTabInToolbar',
	SignInClicked = 'SignInClicked',
	Dismiss = 'Dismiss',
	ChangeAuthMethod = 'ChangeAuthMethod',
	CloseAuthMethod = 'CloseAuthMethod',
	IsPermittingInput = 'IsPermittingInput',
	DrawerOpened = 'DrawerOpened',
	DrawerClosed = 'DrawerClosed',
	ShowStarter = 'ShowStarter',
	ShowMessage = 'ShowMessage',
	SocialIconClick = 'SocialIconClick',
	ExploreSpecificStory = 'ExploreSpecificStory',
	AddNewWallet = 'AddNewWallet',
	ImportNFTs = 'ImportNFTs',
	OpenActionsDrawer = 'OpenActionsDrawer',
	ShowingInfoBar = 'ShowingInfoBar',
	StoryEngagementFirstTimeDuringStorySession = 'StoryEngagementFirstTimeDuringStorySession',
	UserRegistration = 'UserRegistration',
	UserDeleted = 'UserDeleted',
	InfoDrawerOpened = 'InfoDrawerOpened',
	InfoDrawerClosed = 'InfoDrawerClosed',
	SocialAuthClicked = 'SocialAuthClicked',
	UserRequestedAccountDeletion = 'UserRequestedAccountDeletion',
	UserApprovedLogout = 'UserConfirmedLogout',
	UserRequestedLogout = 'UserRequestedLogout',
}

export enum MixpanelPeopleProps {
	StoryImpression = 'StoryImpression',
	StoryEngagement = 'StoryEngagement'
}

export enum MixpanelParentComponents {
	PostCard = 'PostCard',
	PostCardComment = 'PostCardComment',
	ActionCard = 'ActionCard',
	ThoughtCard = 'ThoughtCard',
	PromotedItemCard = 'PromotedItemCard',
	BuyNowCarousel = 'BuyNowCarousel',
	AboutPanel = 'AboutPanel',
	PFPCallout = 'PFPCallout',
	PromotedItemCallout = 'PromotedItemCallout',
	SpaceCallout = 'SpaceCallout',
	Header = 'Header'
}

export enum MixpanelClickLocations {
	Avatar = 'Avatar',
	Name = 'Name'
}

export enum MixpanelEventTypes {
	Interaction = 'Interaction',
	Event = 'Event',
}

export interface IMixpanelSource {
	parentComponent: MixpanelParentComponents
	event?: MixpanelEvents
}

// ----------------------------------------------------------------------

const useMixpanel = () => {
	const context = useContext(MixpanelContext)

	if (!context) throw new Error('Mixpanel context must be use inside MixpanelProvider')

	return context
}

export default useMixpanel
